import React from 'react';
import PropTypes from 'prop-types';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import CloseIcon from '@mui/icons-material/Close';

const tutorialSteps = {
    1: {
        title: "Choose a County",
        content: (<span>Select a county and click the <strong>View Tracts</strong> </span>),
        position: { top: '50%', left: '65%' }
    },
    2: {
        title: "Paint Metrics",
        content: (
            <span style={{ 
                display: 'inline',
                lineHeight: '1.5'
            }}>
                Select
                <FormatColorFillIcon sx={{ 
                    color: '#4CAF50',
                    width: '22px',
                    height: '22px',
                    display: 'inline-flex',
                    verticalAlign: 'text-bottom',
                    marginTop: '2px',
                    margin: '0 4px'
                }}/>
                to paint the map with that metric
            </span>
        ),
        position: { bottom: '50%', left: '400px' }
    },
    3: {
        title: "Display Type",
        content: (
            <span>
                Change the <strong>Display Type</strong> to gain more insight into the data
            </span>
        ),
        position: { bottom: '30%', left: '400px' }
    },
    4: {
        title: "Change Colors",
        content: (
            <span>
                Change the color display in <strong>Color Selection</strong> for different visuals
            </span>
        ),
        position: { bottom: '30%', left: '400px' }
    },
    5: {
        title: "View Tract metrics",
        content: (
            <span>
                Select a <strong>Tract</strong> to view the metrics for that tract. 
            </span>
        ),
        position: { bottom: '55%', left: '50%' }
    },
    6: {
        title: "Change Data Profile",
        content: (
            <span>
                Change <strong>Data Profile</strong> to see different metrics
            </span>
        ),
        position: { bottom: '70%', left: '400px' }
    },
    7: {
        title: "Return to County View",
        content: (
            <span>
                Click <strong>County</strong> to return to the county view. 
            </span>
        ),
        position: { bottom: '70%', left: '50%' }
    },
};

const TutorialOverlay = ({ showTutorial, tutorialStep, setTutorialStep, setShowTutorial }) => {
    if (!showTutorial) return null;

    return (
        <div 
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1000,
                pointerEvents: 'none'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    ...tutorialSteps[tutorialStep].position,
                    padding: '12.5px',
                    background: 'rgba(240, 240, 240, 0.9)',
                    borderRadius: '5px',
                    boxShadow: '0 1.25px 6.25px rgba(0, 0, 0, 0.15)',
                    zIndex: 1001,
                    pointerEvents: 'auto',
                    maxWidth: '225px',
                    border: '21x solid #007BFF'
                }}
            >
                <button 
                    onClick={() => setShowTutorial(false)}
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        background: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: '#FF0000'
                    }}
                >
                    <CloseIcon />
                </button>
                <h3 style={{ 
                    margin: '0 0 6.25px 0',
                    fontSize: '18px',
                    fontWeight: 600,
                    color: '#333'
                }}>
                    {tutorialSteps[tutorialStep].title}
                </h3>
                <p style={{ 
                    margin: '0 0 10px 0',
                    fontSize: '14px',
                    color: '#555'
                }}>
                    {tutorialSteps[tutorialStep].content}
                </p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {tutorialStep > 1 && (
                        <button 
                            onClick={() => setTutorialStep(prev => prev - 1)}
                            style={{
                                padding: '5px 10px',
                                background: '#007BFF',
                                border: 'none',
                                borderRadius: '2.5px',
                                cursor: 'pointer',
                                fontSize: '12.5px',
                                color: '#ffffff'
                            }}
                        >
                            Previous
                        </button>
                    )}
                    {tutorialStep < Object.keys(tutorialSteps).length ? (
                        <button 
                            onClick={() => setTutorialStep(prev => prev + 1)}
                            style={{
                                padding: '5px 10px',
                                background: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '2.5px',
                                cursor: 'pointer',
                                fontSize: '12.5px'
                            }}
                        >
                            Next
                        </button>
                    ) : (
                        <button 
                            onClick={() => setShowTutorial(false)}
                            style={{
                                padding: '5px 10px',
                                background: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '2.5px',
                                cursor: 'pointer',
                                fontSize: '12.5px'
                            }}
                        >
                            Got it!
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

TutorialOverlay.propTypes = {
    showTutorial: PropTypes.bool.isRequired,
    tutorialStep: PropTypes.number.isRequired,
    setTutorialStep: PropTypes.func.isRequired,
    setShowTutorial: PropTypes.func.isRequired
};

export default TutorialOverlay; 