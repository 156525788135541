import React, { useState, useEffect, useRef, useMemo, createContext } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap, ZoomControl, Popup } from "react-leaflet";
import Grid from "@mui/material/Grid";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import PropTypes from 'prop-types';
import mapColors from './map_colors.json';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Link } from 'react-router-dom';
import MetricDisplay from './MetricDisplay';
import { Box, Tooltip } from '@mui/material';
import ColorControls from './ColorControls';
import MetricSelection from './MetricSelection';
import MetricTypeSelector from './MetricTypeSelector';
import LocationMetricDisplayBar from './LocationMetricDisplayBar';
import TutorialOverlay from './TutorialOverlay';
import MetricBoxPlot from './MetricBoxPlot';
import TractStyler from './TractStyler';
import ColorRangeSlider from './ColorRangeSlider';
// Create a context for selected metrics
export const MetricsContext = createContext();


const StateMap = ({ initialState, initialMetrics, hierarchyType, demo = false, metricsByType }) => {
  // Add getMetricTypeIndex at the top of the component
  const getMetricTypeIndex = (type) => {
    switch (type) {
      case 'E': return 0;  // Estimate
      case 'M': return 1;  // Margin of Error
      case 'PE': return 2; // Percentage Estimate
      case 'PM': return 3; // Percentage Margin of Error
      default: return 0;   // Default to Estimate
    }
  };

  const authHeader = useAuthHeader();
  const [selectedState, setSelectedState] = useState(initialState || '');
  const [selectedMetrics, setSelectedMetrics] = useState(initialMetrics || []);
  const [MetricHierarchy, setMetricHierarchy] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [mapDefinitions, setMapDefinitions] = useState(null);
  const [error, setError] = useState(null);
  const [maxBounds, setMaxBounds] = useState(null);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const mapRef = useRef(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [tractGeoJson, setTractGeoJson] = useState(null);
  const [selectedTractId, setSelectedTractId] = useState(null);
  const [isLoadingTracts, setIsLoadingTracts] = useState(false);
  const [viewMode, setViewMode] = useState('counties');
  const [countyGeoJson, setCountyGeoJson] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [showMetricsSelection, setShowMetricsSelection] = useState(false);
  const [primaryMetric, setPrimaryMetric] = useState(null);
  const [opacitySelected, setOpacitySelected] = useState(0.6);
  const [opacityUnselected, setOpacityUnselected] = useState(0.3);
  const lineColor = ['#FF0000', '#000000']
  const [selectedColorGradient, setSelectedColorGradient] = useState(Object.keys(mapColors.list_of_9)[0] || 'red_to_green');
  const [opacityColorGradient, setOpacityColorGradient] = useState(0.3);
  const [isColorControlsCollapsed, setIsColorControlsCollapsed] = useState(false);
  const [selectedHierarchyType, setSelectedHierarchyType] = useState(hierarchyType || 'DP03');
  const [metricsData, setMetricsData] = useState({
    headers: [],
    data: {}
  });
  const [selectedMetricType, setSelectedMetricType] = useState('E'); // Default paint gradient
  const [showTutorial, setShowTutorial] = useState(demo);
  const [tutorialStep, setTutorialStep] = useState(1);
  const [metricRange, setMetricRange] = useState({ min: null, max: null });
  const [currentCountyId, setCurrentCountyId] = useState(null);
  const geoJsonLayerRef = useRef(null);
  const [colorRangeValues, setColorRangeValues] = useState(null);
  const [prevMetricType, setPrevMetricType] = useState(selectedMetricType);
  const [showBoxPlot, setShowBoxPlot] = useState(false);

  // Move tractStyle and colorTractsByMetric to the top
  const tractStyle = React.useCallback((feature) => {
    if (!metricsData) return {};

    return TractStyler({
      feature,
      primaryMetric,
      selectedTractId,
      metricsData,
      selectedMetricType,
      opacityColorGradient,
      lineColor,
      opacitySelected,
      opacityUnselected,
      selectedColorGradient,
      mapColors,
      getMetricTypeIndex,
      customRanges: colorRangeValues
    });
  }, [
    primaryMetric?.id,
    selectedTractId,
    selectedMetricType,
    selectedColorGradient,
    opacityColorGradient,
    opacitySelected,
    opacityUnselected,
    metricsData?.headers,
    colorRangeValues,
    lineColor,
    mapColors,
    getMetricTypeIndex
  ]);

  const colorTractsByMetric = React.useCallback((geoJson, metric) => {
    if (!geoJson || !metric) return;

    // Directly update layer styles without re-rendering
    if (geoJsonLayerRef.current) {
      geoJsonLayerRef.current.eachLayer((layer) => {
        const feature = layer.feature;
        layer.setStyle(tractStyle(feature));
      });
    }
  }, [tractStyle]);

  const handleSetPrimaryMetric = React.useCallback((metric) => {
    setPrimaryMetric(metric);
    setColorRangeValues(null);
    if (viewMode === 'tracts' && tractGeoJson) {
      colorTractsByMetric(tractGeoJson, metric);
    }
  }, [viewMode, tractGeoJson, colorTractsByMetric]);

  // Update useEffect for color range changes
  useEffect(() => {
    if (viewMode === 'tracts' && tractGeoJson && primaryMetric) {
      colorTractsByMetric(tractGeoJson, primaryMetric);
    }
  }, [colorRangeValues, selectedMetricType, opacityColorGradient, selectedColorGradient, colorTractsByMetric, viewMode, tractGeoJson, primaryMetric]);

  const mergeMetricsData = (newData) => {
    setMetricsData(prevData => {
      // If no previous data, initialize with the new data
      if (!prevData.headers.length) {
        return {
          headers: newData.headers,
          data: Object.fromEntries(
            Object.entries(newData)
              .filter(([key]) => !['headers', 'metric'].includes(key))
              .map(([countyId, tractData]) => [
                countyId,
                tractData
              ])
          )
        };
      }

      // Merge headers
      const existingHeadersSet = new Set(prevData.headers);
      const newHeaders = [...prevData.headers];
      const headerMapping = {};
      
      newData.headers.forEach((header, index) => {
        if (!existingHeadersSet.has(header)) {
          headerMapping[header] = newHeaders.length;
          newHeaders.push(header);
        } else {
          headerMapping[header] = prevData.headers.indexOf(header);
        }
      });

      // Merge data
      const mergedData = { ...prevData.data };
      
      // Process only county and tract data (skip headers and metric)
      Object.entries(newData)
        .filter(([key]) => !['headers', 'metric'].includes(key))
        .forEach(([countyId, tractData]) => {
          if (!mergedData[countyId]) {
            mergedData[countyId] = {};
          }
          
          Object.entries(tractData).forEach(([tractId, values]) => {
            if (!mergedData[countyId][tractId]) {
              // Initialize array with nulls for all possible metrics
              mergedData[countyId][tractId] = new Array(newHeaders.length).fill(null);
            }
            
            // Ensure values is an array and map the values to the correct positions
            if (Array.isArray(values)) {
              values.forEach((value, index) => {
                const targetIndex = headerMapping[newData.headers[index]];
                if (targetIndex !== undefined) {
                  mergedData[countyId][tractId][targetIndex] = value;
                }
              });
            }
          });
        });

      return {
        headers: newHeaders,
        data: mergedData
      };
    });
  };

  useEffect(() => {
    const fetchMapDefinitions = async () => {
      try {
        const definitionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/utils/state_map_definitions`);
        setMapDefinitions(definitionsResponse.data);

        if (selectedState) {  // Only fetch if we have a selectedState
          const geoJsonResponse = await axios.get(`${process.env.REACT_APP_API_URL}/geometries/counties/${selectedState}`);
          setCountyGeoJson(geoJsonResponse.data);
          setGeoJsonData(geoJsonResponse.data);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMapDefinitions();
  }, [selectedState]);

  useEffect(() => {
    const fetchHierarchyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/utils/metric-hierarchies/${selectedHierarchyType}`
        );
        setMetricHierarchy(response.data.categories);
      } catch (error) {
        console.error('Error fetching hierarchy data:', error);
      }
    };

    fetchHierarchyData();
  }, [selectedHierarchyType]);

  useEffect(() => {
    
    if (mapDefinitions && selectedState) {
      
      const state = mapDefinitions[selectedState.toLowerCase().replace(/ /g, '_')];
      const newMaxBounds = L.latLngBounds(
        [state.max_south-10, state.max_west-10],
        [state.max_north+10, state.max_east+10]
      );
      setMaxBounds(newMaxBounds);
    } else {
      // Default bounds for the entire US
      const defaultBounds = L.latLngBounds(
        [18.905547-2, -195.000000+2], // Southwest coordinates
        [71.341324+2, -66.934570+2]   // Northeast coordinates
      );
      setMaxBounds(defaultBounds);
    }
  }, [mapDefinitions, selectedState]);

  // Use useMemo to compute initialCenter
  const initialCenter = useMemo(() => {
    if (selectedState && mapDefinitions ) {
      const def = mapDefinitions[selectedState.toLowerCase().replace(/ /g, '_')];
      return [
        def.centroid_lat,
        def.centroid_long
      ];
    }
    return [39.8283, -98.5795]; // Default center of US
  }, [mapDefinitions, selectedState]);

  const initialZoom = 7;
  const minZoom =  2.5;
  const maxZoom =  20;

  // Add this useEffect hook
  useEffect(() => {
    if (mapRef.current) {
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 100); // Delay to allow for CSS transition
    }
  }, [isPanelVisible]);

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const style = React.useMemo(() => (feature) => {
    const isSelected = selectedFeatureId === feature.properties.GEOID;
    
    return {
      fillColor: isSelected ? '#2196f3' : '#ffffff',
      weight: isSelected ? 3 : 2,
      opacity: isSelected ? opacitySelected : opacityUnselected,
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  }, [selectedFeatureId, opacitySelected, opacityUnselected, lineColor]);

  const onEachFeature = React.useCallback((feature, layer) => {
    layer.on({
      click: (e) => {
        L.DomEvent.stopPropagation(e);
        setSelectedFeatureId(feature.properties.GEOID);
        setSelectedFeature(feature);
        setCurrentCountyId(feature.properties.GEOID.slice(-3));
        
        layer.setStyle(style(feature));
      },
    });
  }, [style]);

  // Helper function to get popup position
  const getPopupPosition = (feature) => {
    // Try to get coordinates from different possible properties
    if (feature.properties.centroid_lat && feature.properties.centroid_long) {
      return [feature.properties.centroid_lat, feature.properties.centroid_long];
    }
    
    // Fallback: try to calculate center of the feature
    if (feature.geometry) {
      try {
        const bounds = L.geoJSON(feature).getBounds();
        return bounds.getCenter();
      } catch (error) {
        console.error('Error calculating feature center:', error);
      }
    }
    
    // Final fallback: use map center
    return initialCenter;
  };

  const handleViewTracts = async (geoid) => {
    try {
      setIsLoadingTracts(true);
      const countyId = geoid.slice(-3);
      setCurrentCountyId(countyId);
      
      // Get geometry data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/geometries/tracts_geometry/${selectedState}/${geoid}`
      );

      // Fetch geometry data
      const geometryResponse = await axios.get(response.data.geometry);
      const tractData = geometryResponse.data;
      
      // Update map with geometry only first
      setTractGeoJson(tractData);
      setGeoJsonData(null);
      setSelectedFeature(null);
      setViewMode('tracts');
      
      // Update map view if bounds are available
      if (tractData.bounds && 
          tractData.bounds.centroid_lat && 
          tractData.bounds.centroid_long) {
        mapRef.current.flyTo(
          [tractData.bounds.centroid_lat, tractData.bounds.centroid_long],
          10,
          {
            duration: 2.5,
            easeLinearity: 0.25
          }
        );
      }

      // Get the county ID from the GEOID
      const countyMetricsData = metricsData.data[countyId];

      // Update the tract GeoJSON with the metrics data
      const updatedTractData = {
        ...tractData,
        features: tractData.features.map(feature => {
          const tractId = feature.properties.TRACTCE;
          const metricValues = countyMetricsData?.[tractId] || [];
          
          return {
            ...feature,
            properties: {
              ...feature.properties,
              metrics: metricsData.headers.reduce((acc, header, index) => {
                acc[header] = metricValues[index];
                return acc;
              }, {})
            }
          };
        })
      };

      setTractGeoJson(updatedTractData);

    } catch (error) {
      console.error('Error fetching tract data:', error);
    } finally {
      setIsLoadingTracts(false);
    }
  };

  // Update the onEachTract function to handle the new data structure
  const onEachTract = (feature, layer) => {
    layer.on({
      click: (e) => {
        L.DomEvent.stopPropagation(e);
        
        // If clicking the same tract, deselect it
        if (selectedTractId === feature.properties.GEOID) {
          setSelectedTractId(null);
          setSelectedFeature(null);
          colorTractsByMetric(tractGeoJson, primaryMetric);
          return;
        }
        
        setSelectedTractId(feature.properties.GEOID);
        
        // Update the feature's metrics with current data
        const countyId = feature.properties.COUNTYFP;
        const tractId = feature.properties.TRACTCE;
        const metricValues = metricsData.data[countyId]?.[tractId] || [];
        
        // Create metrics object from current data
        const metrics = {};
        metricsData.headers.forEach((header, index) => {
          metrics[header] = metricValues[index];
        });
        
        // Update feature properties with new metrics
        const updatedFeature = {
          ...feature,
          properties: {
            ...feature.properties,
            metrics: metrics
          }
        };
        
        setSelectedFeature(updatedFeature);
        colorTractsByMetric(tractGeoJson, primaryMetric);
      },
    });
  };

  // You might want to add a function to go back to counties view
  const handleBackToCounties = () => {
    setTractGeoJson(null);
    setSelectedTractId(null);
    setViewMode('counties');
    setGeoJsonData(countyGeoJson);
    setSelectedFeature(null);
    setSelectedFeatureId(null);
    setCurrentCountyId(null);
    setPrimaryMetric(null);
    // Reset map view to initial state
    if (mapRef.current) {
      mapRef.current.flyTo(initialCenter, initialZoom);
    }
  };

  // Helper function to get all children for an item
  const getChildren = (itemId) => {
    return Object.values(MetricHierarchy).filter(item => item.parent === itemId);
  };

  // Add this new function to fetch metrics data
  const fetchMetricsData = async (metrics) => {
    if (!selectedState) return;
    
    try {
        // Choose endpoint based on demo mode
        const endpoint = demo 
            ? `${process.env.REACT_APP_API_URL}/geometries/demo_state_metrics/${selectedState}`
            : `${process.env.REACT_APP_API_URL}/geometries/state_metrics/${selectedState}`;
        
        // Only include auth header if not in demo mode
        const config = demo ? {} : {
            headers: {
                Authorization: authHeader
            }
        };
        
        const response = await axios.post(
            endpoint,
            {
                Metric_ids: metrics.flatMap(metric => metric.item_mapping)
            },
            config
        );

        // Fetch data from each URL returned in the response
        const metricPromises = Object.entries(response.data.metrics).map(async ([baseMetric, url]) => {
            const metricResponse = await axios.get(url);
            return metricResponse.data;
        });

        const metricsResults = await Promise.all(metricPromises);
        
        metricsResults.forEach(metricData => {
            mergeMetricsData(metricData);
        });

        if (viewMode === 'tracts' && tractGeoJson) {
            const updatedTractData = {
                ...tractGeoJson,
                features: tractGeoJson.features.map(feature => {
                    const countyId = feature.properties.COUNTYFP;
                    const tractId = feature.properties.TRACTCE;
                    const metricValues = metricsData.data[countyId]?.[tractId] || [];
                    
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            metrics: metricsData.headers.reduce((acc, header, index) => {
                                acc[header] = metricValues[index];
                                return acc;
                            }, {})
                        }
                    };
                })
            };
            setTractGeoJson(updatedTractData);
        }
    } catch (error) {
        console.error('Error fetching metrics data:', error);
    }
  };

  // Modify the handleMetricChange function
  const handleMetricChange = async (newMetrics) => {
    let updatedMetrics;
    
    if (typeof newMetrics === 'function') {
      // If newMetrics is a function, apply it to the current selectedMetrics
      updatedMetrics = newMetrics(selectedMetrics);
    } else {
      // If newMetrics is an array, use it directly
      updatedMetrics = Array.isArray(newMetrics) ? newMetrics : [];
    }
    
    // Update the state
    setSelectedMetrics(updatedMetrics);

    // Clear all selections and displays
    setSelectedTractId(null);
    setSelectedFeature(null);
    
    // Reset metrics data
    setMetricsData({
      headers: [],
      data: {}
    });

    // Only fetch if we have metrics to fetch
    if (updatedMetrics.length > 0) {
      await fetchMetricsData(updatedMetrics);
    }
  };

  // Update the useEffect for initial metrics
  useEffect(() => {
    if (initialMetrics?.length > 0) {
      fetchMetricsData(initialMetrics);
    }
  }, [initialMetrics, selectedState]);

  // Update MapClickHandler to handle deselection
  const MapClickHandler = () => {
    const map = useMap();
    
    useEffect(() => {
      const handleMapClick = (e) => {
        if (viewMode === 'tracts') {
          setSelectedTractId(null);
          setSelectedFeature(null);
          // Update colors when deselecting
          colorTractsByMetric(tractGeoJson, primaryMetric);
        }
      };

      map.on('click', handleMapClick);

      return () => {
        map.off('click', handleMapClick);
      };
    }, [viewMode]);

    return null;
  };

  // Add this helper function to group metrics
  const groupMetrics = (metrics) => {
    const groups = {};
    
    // Get all base metric IDs from selectedMetrics (without E, M, PE, PM suffixes)
    const selectedBaseMetricIds = selectedMetrics.flatMap(m => 
      m.item_mapping.map(id => id.replace(/[EMP]+$/, ''))
    );
    
    Object.entries(metrics).forEach(([metricId, value]) => {
      // Get the base name without suffixes
      const baseName = metricId.replace(/[EMP]+$/, '');
      
      // Only process metrics whose base name is in selectedMetrics
      if (!selectedBaseMetricIds.includes(baseName)) {
        return;
      }

      if (!groups[baseName]) {
        // Find the metric from selectedMetrics that matches this base ID
        const metric = selectedMetrics.find(m => 
          m.item_mapping.some(id => id.startsWith(baseName))
        );
        
        groups[baseName] = {
          label: metric?.label || baseName,
          values: {}
        };
      }
      
      if (metricId.endsWith('PE')) groups[baseName].values.PE = value;
      else if (metricId.endsWith('PM')) groups[baseName].values.PM = value;
      else if (metricId.endsWith('E') && !metricId.endsWith('PE')) groups[baseName].values.E = value;
      else if (metricId.endsWith('M') && !metricId.endsWith('PM')) groups[baseName].values.M = value;
    });
    
    return groups;
  };

  // Update useEffect to watch for hierarchyType changes and set appropriate metrics
  useEffect(() => {
    const updateMetrics = async () => {
      setSelectedHierarchyType(hierarchyType);
      // If in demo mode and we have metricsByType, set the matching metrics
      if (demo && metricsByType && metricsByType[hierarchyType]) {
        const newMetrics = metricsByType[hierarchyType];
        setSelectedMetrics(newMetrics);
        
        // Reset metrics data
        setMetricsData({
          headers: [],
          data: {}
        });

        // Fetch new metrics data
        if (newMetrics.length > 0) {
          await fetchMetricsData(newMetrics);
        }
      }
    };

    updateMetrics();
  }, [hierarchyType, demo, metricsByType]);

  // Add this useEffect to calculate min/max when primary metric or county changes
  useEffect(() => {
    if (!primaryMetric || !metricsData.data || !currentCountyId) return;

    const metricIndex = metricsData.headers.indexOf(primaryMetric.item_mapping[getMetricTypeIndex(selectedMetricType)]);
    if (metricIndex === -1) return;

    const countyData = metricsData.data[currentCountyId];
    if (!countyData) return;

    const allValues = Object.values(countyData)
      .map(tractData => tractData[metricIndex])
      .filter(value => value !== null && value !== undefined && Number(value) >= 0)
      .map(Number);

    if (allValues.length === 0) return;

    const min = Math.min(...allValues);
    const max = Math.max(...allValues);
    
    setMetricRange(prev => {
      if (prev.min === min && prev.max === max) return prev;
      return { min, max };
    });
  }, [primaryMetric, metricsData.data, currentCountyId, metricsData.headers, selectedMetricType, getMetricTypeIndex]);

  // Add this useEffect to reset colorRangeValues when metric type changes
  useEffect(() => {
    setColorRangeValues(null);
  }, [selectedMetricType]);

  // Add effect to reset colorRangeValues when primary metric changes
  useEffect(() => {
    if (primaryMetric?.id) {
      setColorRangeValues(null);
    }
  }, [primaryMetric?.id]);

  return (
    <div style={{
      position: "relative",
      width: "100%",
      height: demo ? "100%" : "100vh",
      overflow: "hidden",
      margin: 0,
      padding: 0
    }}>
      <LocationMetricDisplayBar 
        selectedState={selectedState}
        selectedFeature={selectedFeature}
        viewMode={viewMode}
        selectedCounty={selectedCounty}
        primaryMetric={primaryMetric}
        handleBackToCounties={handleBackToCounties}
        handleViewTracts={handleViewTracts}
        isPanelVisible={isPanelVisible}
      />

      <div style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}>
        {maxBounds && (
          <MapContainer 
            key={`${selectedState}-${initialCenter[0]}-${initialCenter[1]}`}
            center={initialCenter}
            zoom={initialZoom} 
            maxBounds={maxBounds}
            maxBoundsViscosity={1.0}
            minZoom={minZoom}
            maxZoom={maxZoom}
            style={{
              width: "100%",
              height: "100%"
            }}
            ref={mapRef}
            zoomControl={false}
            attributionControl={false}
          >
            <ZoomControl position="topright" />
            <TileLayer
              attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url={`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=8f074345-7c64-4b84-bedc-a74592e223c5`}
              minZoom={0}
              maxZoom={20}
            />
            {/* County boundaries */}
            {viewMode === 'counties' && geoJsonData && (
              <GeoJSON 
                key={selectedFeatureId}
                data={geoJsonData} 
                style={style}
                onEachFeature={onEachFeature}
              />
            )}
            
            {/* Tract boundaries */}
            {viewMode === 'tracts' && tractGeoJson && (
              <GeoJSON 
                ref={geoJsonLayerRef}
                key={`tracts-${currentCountyId}-${primaryMetric?.id}`}
                data={tractGeoJson}
                style={tractStyle}
                onEachFeature={onEachTract}
              />
            )}
            {selectedFeature && (
              <Popup
                position={getPopupPosition(selectedFeature)}
                maxHeight={400}
              >
                <div style={{ maxWidth: '300px' }}>
                  <h3 style={{ 
                    margin: '0 0 15px 0', 
                    fontSize: '1.2em',
                    borderBottom: '2px solid #2196f3',
                    paddingBottom: '8px',
                    color: '#1976d2'
                  }}>
                    {viewMode === 'counties' 
                      ? selectedFeature.properties.NAMELSAD 
                      : `Tract ${selectedFeature.properties.GEOIDFQ}`
                    }
                  </h3>
                  
                  {/* Metrics Display */}
                  {selectedFeature.properties.metrics && (
                    <Box sx={{ mt: 2 }}>
                      {Object.entries(groupMetrics(selectedFeature.properties.metrics)).map(([baseName, group]) => (
                        <MetricDisplay
                          key={baseName}
                          label={group.label}
                          values={group.values}
                          selectedType={selectedMetricType}
                        />
                      ))}
                    </Box>
                  )}

                  {/* Area Information */}
                  <div style={{ 
                    marginTop: '15px',
                    padding: '12px',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                  }}>
                    <div style={{ 
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '8px',
                      borderBottom: '1px solid #e0e0e0',
                      paddingBottom: '4px'
                    }}>
                      <span style={{ color: '#666', fontWeight: '500' }}>Area:</span>
                      <span style={{ fontWeight: '600', color: '#333' }}>
                        {(selectedFeature.properties.ALAND / 2589988).toFixed(0)} sq mi
                      </span>
                    </div>
                    <div style={{ 
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <span style={{ color: '#666', fontWeight: '500' }}>Water Area:</span>
                      <span style={{ fontWeight: '600', color: '#333' }}>
                        {(selectedFeature.properties.AWATER / 2589988).toFixed(1)} sq mi
                      </span>
                    </div>
                  </div>

                  {/* View Tracts Button */}
                  {viewMode === 'counties' && (
                    <button 
                      onClick={() => handleViewTracts(selectedFeature.properties.GEOID)}
                      disabled={isLoadingTracts}
                      style={{
                        marginTop: '15px',
                        padding: '10px',
                        backgroundColor: isLoadingTracts ? '#bdbdbd' : '#2196f3',
                        color: 'white',
                        border: 'none',
                        borderRadius: '6px',
                        cursor: isLoadingTracts ? 'not-allowed' : 'pointer',
                        width: '100%',
                        fontWeight: '600',
                        transition: 'background-color 0.2s ease',
                        boxShadow: '0 2px 4px rgba(33,150,243,0.2)',
                        ':hover': {
                          backgroundColor: isLoadingTracts ? '#bdbdbd' : '#1976d2'
                        }
                      }}
                    >
                      {isLoadingTracts ? 'Loading Tracts...' : 'View Tracts'}
                    </button>
                  )}
                </div>
              </Popup>
            )}
            <MapClickHandler />
          </MapContainer>
        )}
        {error && <div style={{ position: 'absolute', zIndex: 2, backgroundColor: 'white', padding: '10px' }}>Error: {error}</div>}
      </div>

      <div style={{
        position: "absolute",
        left: isPanelVisible ? "0" : "-400px",
        top: "0",
        height: "100%",
        width: "400px",
        backgroundColor: "white",
        padding: "20px",
        boxSizing: "border-box",
        border: '1px solid #ccc',
        borderRadius: '0 5px 5px 0',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        transition: "left 0.3s ease-in-out",
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <button
          onClick={togglePanelVisibility}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '5px 10px',
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 1
          }}
        >
          Hide Panel
        </button>

        <h2>Control Panel</h2>

        <FormControl 
          fullWidth 
          sx={{ 
            marginBottom: 2,
            flexShrink: 0,
            '& .MuiInputBase-root': {
              fontSize: '1.1rem',
              minHeight: '36px'
            },
            '& .MuiInputLabel-root': {
              fontSize: '1.1rem'
            },
            '& .MuiMenuItem-root': {
              fontSize: '1.1rem',
              padding: '12px 16px'
            }
          }}
        >
          <InputLabel id="hierarchy-type-label">Data Profile</InputLabel>
          <Select
            labelId="hierarchy-type-label"
            id="hierarchy-type-select"
            value={selectedHierarchyType}
            label="Data Profile"
            onChange={async (e) => {
              const newType = e.target.value;
              setSelectedHierarchyType(newType);
              
              // Set the metrics for the new profile type if in demo mode
              if (demo && metricsByType && metricsByType[newType]) {
                const newMetrics = metricsByType[newType];
                setSelectedMetrics(newMetrics);
                
                // Reset metrics data
                setMetricsData({
                  headers: [],
                  data: {}
                });

                // Fetch new metrics data
                if (newMetrics.length > 0) {
                  await fetchMetricsData(newMetrics);
                }
              }
            }}
          >
            <MenuItem value="DP02">Social Characteristics</MenuItem>
            <MenuItem value="DP03">Economic Characteristics</MenuItem>
            <MenuItem value="DP04">Housing Characteristics</MenuItem>
            <MenuItem value="DP05">Demographic Characteristics</MenuItem>
          </Select>
        </FormControl>

        <div style={{
          flexGrow: 1,
          overflowY: 'auto',
          marginRight: '-20px',
          paddingRight: '20px',
          marginLeft: '-20px',
          paddingLeft: '20px',
        }}>
          <MetricSelection 
            showMetricsSelection={showMetricsSelection}
            setShowMetricsSelection={setShowMetricsSelection}
            MetricHierarchy={MetricHierarchy}
            expandedCategories={expandedCategories}
            setExpandedCategories={setExpandedCategories}
            handleMetricChange={handleMetricChange}
            getChildren={getChildren}
            selectedMetrics={selectedMetrics}
            primaryMetric={primaryMetric}
            handleSetPrimaryMetric={handleSetPrimaryMetric}
          />
            {/* Back to Counties button */}
            {viewMode === 'tracts' && (
            <button
              onClick={handleBackToCounties}
              style={{
                marginTop: '10px',
                marginBottom: '20px',
                padding: '8px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                width: '100%'
              }}
            >
              Back to Counties
            </button>
          )}
        <MetricTypeSelector
          selectedType={selectedMetricType}
          onChange={(type) => {
            setSelectedMetricType(type);
            setColorRangeValues(null);
          }}
        />
          <ColorControls 
            selectedColorGradient={selectedColorGradient}
            setSelectedColorGradient={setSelectedColorGradient}
            opacityUnselected={opacityUnselected}
            setOpacityUnselected={setOpacityUnselected}
            opacitySelected={opacitySelected}
            setOpacitySelected={setOpacitySelected}
            opacityColorGradient={opacityColorGradient}
            setOpacityColorGradient={setOpacityColorGradient}
            isColorControlsCollapsed={isColorControlsCollapsed}
            setIsColorControlsCollapsed={setIsColorControlsCollapsed}
          />
        
        </div>
      </div>

      {/* Show Panel button */}
      <button
        onClick={togglePanelVisibility}
        style={{
          position: 'absolute',
          top: '58px',
          left: isPanelVisible ? '-200px' : '10px',
          zIndex: 1001,
          padding: '5px 5px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: "left 0.3s ease-in-out"
        }}
      >
        Show Panel
      </button>

      {/* Return Home Link - Only show if not in demo mode */}
      {!demo && (
        <Link
          to="/account"
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            zIndex: 1001,
            padding: '8px 16px',
            backgroundColor: '#2196f3',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'background-color 0.3s ease',
            fontSize: '14px',
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
              backgroundColor: '#1976d2'
            }
          }}
        >
          Return Home
        </Link>
      )}

      {/* Add tutorial overlay */}
      {demo && (
        <TutorialOverlay 
          showTutorial={showTutorial}
          tutorialStep={tutorialStep}
          setTutorialStep={setTutorialStep}
          setShowTutorial={setShowTutorial}
        />
      )}

      {primaryMetric && metricRange.min !== null && metricRange.max !== null && (
        <>
              <div
              onClick={() => setShowBoxPlot(!showBoxPlot)}
              style={{
                position: 'absolute',
                bottom: showBoxPlot ? '110px' : '10px',
                transition: 'bottom 0.3s ease-in-out',
                right: isPanelVisible ? 'calc(50% + 200px)' : 'calc(50% + 400px)',
                padding: '4px 8px',
                backgroundColor:'#2196f3',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '0.875rem',
                display: 'block',
                margin: '0px auto 10px auto',
                zIndex: 1001,
              }}
            >
              {showBoxPlot ? 'Hide Distribution' : 'See Distribution'}
            </div>
          <div style={{
              position: 'absolute',
              bottom: '20px',
              left: isPanelVisible ? 'calc(50% + 200px)' : '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              maxWidth: '800px',
              zIndex: 1000,
              padding: '0px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              pointerEvents: 'auto',
              userSelect: 'none'
            }}>


          <div style={{
            marginBottom: showBoxPlot ? '200px' : '100px',
            transition: 'margin-bottom 0.3s ease-in-out'
          }}>
            <ColorRangeSlider 
              min={metricRange.min}
              max={metricRange.max}
              metricType={selectedMetricType}
              currentValues={colorRangeValues}
              metricsData={metricsData}
              primaryMetric={primaryMetric}
              selectedMetricType={selectedMetricType}
              currentCountyId={currentCountyId}
              onChange={(values) => {
                setColorRangeValues(prev => {
                  if (!prev || !values || prev.length !== values.length) {
                    setSelectedTractId(null);
                    setSelectedFeature(null);
                    return values;
                  }
                  const hasChanged = values.some((val, idx) => prev[idx] !== val);
                  if (hasChanged) {
                    setSelectedTractId(null);
                    setSelectedFeature(null);
                    return values;
                  }
                  return prev;
                });
              }}
            />
          </div>

          {showBoxPlot && (
            <MetricBoxPlot 
              metricsData={{
                headers: metricsData.headers,
                data: { [currentCountyId]: metricsData.data[currentCountyId] || {} }
              }}
              primaryMetric={primaryMetric}
              selectedMetricType={selectedMetricType}
            />
          )}
        </div>
        </>
      )}

    </div>
  );
};

// Add prop types for type checking
StateMap.propTypes = {
  initialState: PropTypes.string,
  initialMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      item_mapping: PropTypes.array
    })
  ),
  hierarchyType: PropTypes.string,
  demo: PropTypes.bool,
  metricsByType: PropTypes.object
};

export default StateMap;
