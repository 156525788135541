import React, { useEffect, useRef } from 'react';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import './ColorRangeSlider.css';
const colors_5 = ["#a50026","#f46d43","#fee08b","#a6d96a","#1a9850"];

// Merging tooltips helper function
function mergeTooltips(slider, threshold, separator) {
  const textIsRtl = getComputedStyle(slider).direction === 'rtl';
  const isRtl = slider.noUiSlider.options.direction === 'rtl';
  const isVertical = slider.noUiSlider.options.orientation === 'vertical';
  const tooltips = slider.noUiSlider.getTooltips();
  const origins = slider.noUiSlider.getOrigins();

  // Move tooltips into the origin element
  tooltips.forEach((tooltip, index) => {
    if (tooltip) {
      origins[index].appendChild(tooltip);
    }
  });

  slider.noUiSlider.on('update', function (values, handle, unencoded, tap, positions) {
    const pools = [[]];
    const poolPositions = [[]];
    const poolValues = [[]];
    let atPool = 0;

    // Assign the first tooltip to the first pool, if the tooltip is configured
    if (tooltips[0]) {
      pools[0][0] = 0;
      poolPositions[0][0] = positions[0];
      poolValues[0][0] = values[0];
    }

    for (let i = 1; i < positions.length; i++) {
      if (!tooltips[i] || (positions[i] - positions[i - 1]) > threshold) {
        atPool++;
        pools[atPool] = [];
        poolValues[atPool] = [];
        poolPositions[atPool] = [];
      }

      if (tooltips[i]) {
        pools[atPool].push(i);
        poolValues[atPool].push(values[i]);
        poolPositions[atPool].push(positions[i]);
      }
    }

    pools.forEach((pool, poolIndex) => {
      const handlesInPool = pool.length;

      for (let j = 0; j < handlesInPool; j++) {
        const handleNumber = pool[j];

        if (j === handlesInPool - 1) {
          let offset = 0;

          poolPositions[poolIndex].forEach((value) => {
            offset += 1000 - value;
          });

          const direction = isVertical ? 'bottom' : 'right';
          const last = isRtl ? 0 : handlesInPool - 1;
          const lastOffset = 1000 - poolPositions[poolIndex][last];
          offset = (textIsRtl && !isVertical ? 100 : 0) + (offset / handlesInPool) - lastOffset;

          // Center this tooltip over the affected handles
          tooltips[handleNumber].innerHTML = poolValues[poolIndex].join(separator);
          tooltips[handleNumber].style.display = 'block';
          tooltips[handleNumber].style[direction] = offset + '%';
        } else {
          // Hide this tooltip
          tooltips[handleNumber].style.display = 'none';
        }
      }
    });
  });
}

const ColorRangeSlider = ({ 
  min = null, 
  max = null, 
  metricType = '', 
  onChange, 
  currentValues = null,
  showBoxPlot = true
}) => {
  const sliderRef = useRef(null);
  const isPercentage = metricType === 'PE' || metricType === 'PM';
  
  const actualMin = isPercentage ? 0 : (min ?? 0);
  const actualMax = isPercentage ? 100 : (max ?? 1000);
  const showValues = min !== null && max !== null;

  // Helper function to calculate evenly distributed values across the range
  const calculateRangeValues = (min, max) => {
    const range = max - min;
    return [
      min + (range * 0.2),  // 20% of range
      min + (range * 0.4),  // 40% of range
      min + (range * 0.6),  // 60% of range
      min + (range * 0.8),  // 80% of range
    ];
  };

  // Helper function to format values based on metric type
  const formatValue = (value) => {
    if (!showValues) return '';
    const numValue = parseFloat(value);
    if (isPercentage) {
      return `${numValue.toFixed(1)}%`;
    }
    return Math.round(numValue).toString();
  };

  useEffect(() => {
    if (sliderRef.current) {
      if (sliderRef.current.noUiSlider) {
        sliderRef.current.noUiSlider.destroy();
      }

      // Use currentValues if provided, otherwise calculate initial values
      const initialValues = currentValues ? 
        currentValues.slice(1, -1) : // Remove first and last elements (min and max)
        calculateRangeValues(actualMin, actualMax);

      const slider = noUiSlider.create(sliderRef.current, {
        start: initialValues,
        connect: [true, true, true, true, true],
        range: {
          'min': actualMin,
          'max': actualMax
        },
        tooltips: showValues ? [true, true, true, true] : [false, false, false, false],
        format: {
          to: formatValue,
          from: function(value) {
            return parseFloat(value.replace('%', ''));
          }
        }
      });

      const updateColors = () => {
        const connects = sliderRef.current.querySelectorAll('.noUi-connect');
        connects.forEach((connect, index) => {
          connect.style.backgroundColor = colors_5[index];
        });
      };

      updateColors();

      if (showValues) {
        mergeTooltips(sliderRef.current, 8, ' - ');
      }

      slider.on('update', (values) => {
        const numberValues = values.map(v => parseFloat(v.replace('%', '')));
        // Create complete range array with min and max
        const completeRange = [actualMin, ...numberValues, actualMax];
        onChange(completeRange);
        updateColors();
      });

      return () => {
        slider.destroy();
      };
    }
  }, [min, max, onChange, showValues, actualMin, actualMax, isPercentage, currentValues]);

  return (
    <div className={`slider-container ${showBoxPlot ? 'with-boxplot' : ''}`}>
      <div className="slider-header">
        <div className="slider-label">Color Range</div>
        {showValues && (
          <div className="slider-label">
            <span>Range: {formatValue(actualMin)} - {formatValue(actualMax)}</span>
          </div>
        )}
      </div>
      <div ref={sliderRef} className="range-slider"></div>
    </div>
  );
};

export default ColorRangeSlider; 