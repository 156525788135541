import React from 'react';

import USA from "../../assets/images/usa.webp";
import usa_map from "../../assets/images/no_background/usa_map_resized.png";
import small_logo from "../../assets/images/logo_small.webp";
import map_image_default from "../../assets/images/map_image_default.png";

const Images = {
    'USA':USA,
    'small_logo':small_logo,
    'usa_map':usa_map,
    'map_image_default':map_image_default,
}


export const RetrieveImage = (imageName) => { 
    return Images[imageName]
    // return California
};


export default RetrieveImage