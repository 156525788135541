import React from 'react';
import PropTypes from 'prop-types';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import mapColors from './map_colors.json';


const ColorControls = ({
  selectedColorGradient,
  setSelectedColorGradient,
  opacityUnselected,
  setOpacityUnselected,
  opacitySelected,
  setOpacitySelected,
  opacityColorGradient,
  setOpacityColorGradient,
  isColorControlsCollapsed,
  setIsColorControlsCollapsed
}) => {
  return (
    <div style={{
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '4px',
      marginTop: '10px',
      border: '1px solid #ddd'
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '10px'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormatColorFillIcon 
            sx={{ 
              marginRight: '8px',
              color: '#666'
            }} 
          />
          <h3 style={{ margin: 0, fontSize: '18px' }}>Color Controls</h3>
        </div>
        <Tooltip title={isColorControlsCollapsed ? "Expand" : "Collapse"}>
          <KeyboardArrowRightIcon 
            sx={{ 
              cursor: 'pointer',
              transform: isColorControlsCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)',
              transition: 'transform 0.3s ease'
            }}
            onClick={() => setIsColorControlsCollapsed(!isColorControlsCollapsed)}
          />
        </Tooltip>
      </div>
      
      <div style={{
        maxHeight: isColorControlsCollapsed ? '0' : '2000px',
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        opacity: isColorControlsCollapsed ? 0 : 1
      }}>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 0 }}>
          <InputLabel id="color-gradient-select-label">Color Selection</InputLabel>
          <Select
            labelId="color-gradient-select-label"
            id="color-gradient-select"
            value={selectedColorGradient}
            label="Color Selection"
            onChange={(e) => setSelectedColorGradient(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 500,
                  padding: '10px',
                  paddingTop: '20px'
                },
              },
            }}
          >
            {Object.keys(mapColors.list_of_5).map((gradientKey) => (
              <MenuItem 
                key={gradientKey} 
                value={gradientKey}
                style={{
                  height: '80px',
                  padding: '1px',
                  marginBottom: '2px'
                }}
              >
                <div style={{ 
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1px'
                }}>
                  <span style={{ 
                    fontSize: '16px',
                    color: '#333',
                    fontWeight: '500',
                    marginTop: '8px',
                    marginBottom: '2px'
                  }}>
                    {gradientKey.split('_').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}
                  </span>
                  <div style={{ 
                    display: 'flex', 
                    width: '100%',
                    height: '30px',
                    borderRadius: '6px',
                    overflow: 'hidden',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                    marginBottom: '6px'
                  }}>
                    {mapColors.list_of_5[gradientKey].map((color, index) => (
                      <div
                        key={index}
                        style={{
                          flex: 1,
                          backgroundColor: color
                        }}
                      />
                    ))}
                  </div>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ 
          marginTop: '3px',
          backgroundColor: 'white',
          borderRadius: '5px',
          border: '1px solid #ddd',
          fontSize: '14px'
        }}>
          <div>
            <div style={{ marginBottom: '5px' }}>
              <strong>Color Opacity: </strong>
              {(opacityUnselected * 100).toFixed(0)}%
            </div>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={opacityUnselected}
              onChange={(e) => setOpacityUnselected(parseFloat(e.target.value))}
              style={{
                width: '100%',
                height: '8px',
                borderRadius: '4px',
                cursor: 'pointer',
                appearance: 'none',
                backgroundColor: '#ddd',
                outline: 'none',
              }}
            />
          </div>

          <div style={{ marginBottom: '5px' }}>
            <div style={{ marginBottom: '5px' }}>
              <strong>Selected Feature: </strong>
              {(opacitySelected * 100).toFixed(0)}%
            </div>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={opacitySelected}
              onChange={(e) => setOpacitySelected(parseFloat(e.target.value))}
              style={{
                width: '100%',
                height: '8px',
                borderRadius: '4px',
                cursor: 'pointer',
                appearance: 'none',
                backgroundColor: '#ddd',
                outline: 'none',
              }}
            />
          </div>

          <div style={{ marginBottom: '5px' }}>
            <div style={{ marginBottom: '5px' }}>
              <strong>Border Gradient: </strong>
              {(opacityColorGradient * 100).toFixed(0)}%
            </div>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={opacityColorGradient}
              onChange={(e) => setOpacityColorGradient(parseFloat(e.target.value))}
              style={{
                width: '100%',
                height: '8px',
                borderRadius: '4px',
                cursor: 'pointer',
                appearance: 'none',
                backgroundColor: '#ddd',
                outline: 'none',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ColorControls.propTypes = {
  selectedColorGradient: PropTypes.string.isRequired,
  setSelectedColorGradient: PropTypes.func.isRequired,
  opacityUnselected: PropTypes.number.isRequired,
  setOpacityUnselected: PropTypes.func.isRequired,
  opacitySelected: PropTypes.number.isRequired,
  setOpacitySelected: PropTypes.func.isRequired,
  opacityColorGradient: PropTypes.number.isRequired,
  setOpacityColorGradient: PropTypes.func.isRequired,
  isColorControlsCollapsed: PropTypes.bool.isRequired,
  setIsColorControlsCollapsed: PropTypes.func.isRequired
};

export default ColorControls; 