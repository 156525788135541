import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { tooltipStyles } from './utils/tooltipStyles';

const MetricTypeSelector = ({ selectedType, onChange }) => (
  <Box sx={{ mb: 2 }}>
    <div style={{ 
      display: 'flex', 
      alignItems: 'center',
      marginBottom: '8px'
    }}>
      <Typography variant="subtitle1" sx={{ mb: 0 }}>Map Metric Display Type</Typography>
      <Tooltip 
        title={
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            textAlign: 'left',
            width: '100%'
          }}>
            <p style={{ 
              color: '#666',
              margin: '0 0 4px 0',
              textAlign: 'left'
            }}>
              The Display Type determines how the metric is displayed on the map. More specifically, it picks what value is used to paint the map.
            </p>
            <p style={{ 
              margin: '0 0 4px 0',
              textAlign: 'left'
            }}>For example, with <strong>"Health Insurance Coverage":</strong></p>
            <ul style={{ 
              paddingLeft: '20px',
              margin: '0',
              lineHeight: '1.3',
              textAlign: 'left'
            }}>
              <li><strong>Estimate:</strong> shows the estimated number of people covered (710 households)</li>
              <li><strong>Margin of Error:</strong> shows the uncertainty (±50 households)</li>
              <li><strong>% Estimate:</strong> shows the percentage of households covered (92% covered)</li>
              <li><strong>% Margin of Error:</strong> shows the uncertainty in that percentage (±1.5%)</li>
            </ul>
          </div>
        }
        arrow 
        placement="right"
        componentsProps={{
          tooltip: { 
            sx: {
              ...tooltipStyles.tooltip,
              '& .MuiTooltip-tooltip': {
                textAlign: 'left'
              }
            }
          },
          arrow: { sx: tooltipStyles.arrow }
        }}
      >
        <HelpOutlineIcon 
          sx={{ 
            marginLeft: '8px', 
            color: '#FFA500',
            fontSize: '18px',
            cursor: 'help'
          }} 
        />
      </Tooltip>
    </div>
    <Box sx={{ 
      display: 'grid', 
      gridTemplateColumns: '1fr 1fr',
      gap: 1,
    }}>
      {[
        { value: 'E', label: 'Estimate' },
        { value: 'M', label: 'Margin of Error' },
        { value: 'PE', label: '% Estimate' },
        { value: 'PM', label: '% Margin of Error' }
      ].map(({ value, label }) => (
        <Button
          key={value}
          variant={selectedType === value ? "contained" : "outlined"}
          onClick={() => onChange(value)}
          sx={{
            height: '40px',
            textTransform: 'none',
            fontSize: '1rem',
            whiteSpace: 'normal',
            lineHeight: 1.2,
            padding: '8px',
            backgroundColor: selectedType === value ? '#2196f3' : '#ffffff',
            color: selectedType === value ? '#ffffff' : '#2196f3',
            border: '2px solid #2196f3',
            fontWeight: 'medium',
            '&:hover': {
              backgroundColor: selectedType === value ? '#1976d2' : 'rgba(33, 150, 243, 0.08)',
              border: '2px solid #1976d2'
            }
          }}
        >
          {label}
        </Button>
      ))}
    </Box>
  </Box>
);

MetricTypeSelector.propTypes = {
  selectedType: PropTypes.oneOf(['E', 'M', 'PE', 'PM']).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MetricTypeSelector; 