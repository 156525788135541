import React from 'react';
import { Outlet} from "react-router-dom"
import CustomNavbar from './CustomNavbar'
import ErrorPage from './Error'
import MKBox from "components/MKBox";
import SimpleFooter from "./basics/SimpleFooter";

const RootLayout = (props) => {
    return (
        <>
        {props.error ? (
            <ErrorPage />
      ) : (
        <>
        
        
      <MKBox display="flex" flexDirection="column" minHeight="95vh">
      <MKBox flex={1}>
      <CustomNavbar relative_status = {props.relative_status}/>
        <Outlet> </Outlet>
      </MKBox>
        <SimpleFooter light={false} />
    </MKBox>
        
      </>
      )} 
        </>
        );
}

export default RootLayout;







