export const tooltipStyles = {
  tooltip: {
    backgroundColor: 'white !important',
    color: '#333 !important',
    border: '2px solid #2196f3',
    fontSize: '0.875rem',
    padding: '8px 12px',
    maxWidth: '500px !important',
    width: 'max-content',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    '& .MuiTooltip-arrow': {
      color: 'white !important',
      '&::before': {
        backgroundColor: 'white !important',
        border: '2px solid #2196f3'
      }
    }
  },
  arrow: {
    color: 'white !important',
    '&::before': {
      backgroundColor: 'white !important',
      border: '2px solid #2196f3'
    }
  }
}; 