import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { Link } from 'react-router-dom';
import Icon from "@mui/material/Icon";
import CircularProgress from '@mui/material/CircularProgress';
import StateSVGComponent from '../images/StateSVGs';
import './SubscriptionsView.css';

const SubscriptionsView = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const authHeader = useAuthHeader();
          
  useEffect(() => {
    const config = {
      headers: {
        Authorization: authHeader
      }
    };
 
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/user-subscriptions`, config)
      .then(response => {
        setSubscriptions(response.data.data);
      })
      .catch(error => {
        setError(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authHeader]);

  return (
    <div className="subscriptions-container">
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
          <CircularProgress />
        </div>
      ) : subscriptions.length > 0 ? (
        <table className="subscriptions-table">
          <thead>
            <tr>
              <th>Subscription Name</th>
              <th>Status</th>
              <th>Map</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((subscription) => (
              <tr key={subscription.id}>
                <td>
                  <div className="subscription-name">
                    <StateSVGComponent stateName={subscription.name} size="24px" color="orange" />
                    <span>{subscription.name}</span>
                  </div>
                </td>
                <td>{subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}</td>
                <td>
                  <Link to={`map-app/${subscription.name.toLowerCase().replace(/\s+/g, '_')}`} className="map-button">
                    <Icon className="map-icon">map</Icon>
                    Go to Map
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-subscriptions">
          You have no active subscriptions.
        </div>
      )}
    </div>
  );
};

export default SubscriptionsView;
