import React, { useEffect,} from 'react';
import { Card, Col} from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import PurchasedProductsView from '../product/PurchasedProductsView';
import SubscriptionsView from '../Subscriptions/SubscriptionsView';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { GetAdmin } from './AuthDecode';
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';

function AccountPage() {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
    

    const is_admin = GetAdmin();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/user/login");
        }
    }, [isAuthenticated, navigate]);

    if(isAuthenticated){
        return (
            <>

    <MKBox component="section" py={-1}>
    <Container>
        <Grid container spacing={2} flexDirection="column" pt={2}>
            <Box sx={{ pt:{xs:8, md:3}, pb:{xs:8, md:3} }}>
                <Grid container>
                    <Grid item xs={.2} md={.2}></Grid>
                    
                    <Grid item xs={12} md={3.8} >
                    <Box
                        sx={{
                            position: 'sticky',
                            top: { xs: 4, md: 1 },
                            minHeight: 300,
                            overflowY: 'auto',
                            background: 'white',
                            padding: 1,
                            margin: 1,
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px'
                        }} >
                        
                        <CardContent>
                            <Typography  fontSize={'20px'} variant="h4" component="div" sx={{ borderBottom: '1px solid black' }}>
                                Account Info
                            </Typography>
                            <Link to="/contact-us" style={{ textDecoration: 'none', color: 'black' }}>
                            <Grid container alignItems="center" sx={{ height: '40px', borderBottom: '1px solid black' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Icon fontSize="small" sx={{ mr: 1 }}>add</Icon>
                                    <Typography variant="body1" component="div" sx={{ fontSize: '16px' }}>
                                        Have an Inquiry?
                                    </Typography>
                                </Box>
                            </Grid>
                            </Link>
                            <Link to="/user/logout" style={{ textDecoration: 'none', color: 'black' }}>
                                <Grid container alignItems="center" sx={{ height: '40px', borderBottom: '1px solid black' }}>
                                    <Icon fontSize="small" sx={{ mr: 1 }}>add</Icon>
                                    <Typography fontSize={'16px'} variant="body1" component="div">
                                        Logout
                                    </Typography>
                                </Grid>
                            </Link>
                            {is_admin ? (
                            <Link to="/admin/panel" style={{ textDecoration: 'none', color: 'black' }}>
                                <Grid container alignItems="center" sx={{ height: '40px', borderBottom: '1px solid black' }}>
                                    <Icon fontSize="small" sx={{ mr: 1 }}>add</Icon>
                                    <Typography fontSize={'16px'} variant="body1" component="div">
                                    Admin
                                    </Typography>
                                </Grid>
                            </Link>
                            ) : null}
                        </CardContent>
                        </Box>
                    </Grid>
                    <Grid item xs={.2} md={.2}></Grid>
                    
                <Grid item xs={12} md={7.8}>
                    <Grid item xs={12} md={12}>
                        <Box
                            sx={{
                                position: 'sticky',
                                top: { xs: 4, md: 1 },
                                minHeight: 100,
                                overflowY: 'auto',
                                background: 'white',
                                padding: 1,
                                margin: 1,
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px'
                            }}
                        >
                            <Card sx={{ width: '100%' }}>
                                <CardContent className="d-flex flex-column align-items-center">
                                    <Typography variant="h5" component="div">
                                        Missing Something
                                    </Typography>
                                    <Typography variant="body2">
                                        Missing something? If you cant find somehting in our store send us a request and we will see if we can get it for you!
                                    </Typography>
                                        <MKButton variant="gradient" color="info"  to="/contact-us" component={Link} >
                                        Send Request
                                        </MKButton>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box
                            sx={{
                                position: 'sticky',
                                top: { xs: 4, md: 1 },
                                minHeight: 300,
                                overflowY: 'auto',
                                background: 'white',
                                padding: 1,
                                margin: 1,
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px'
                            }}
                        >
                                <CardContent className="d-flex flex-column align-items-left">
                                    <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                                        Your Subscriptions
                                    </Typography>
                                    <Col md={12}>
                                        <SubscriptionsView/>
                                    </Col>
                                    <Typography variant="h5" component="div" sx={{ mt: 4, mb: 3 }}>
                                        Your Reports 
                                    </Typography>
                                    <Col md={12}>
                                        <PurchasedProductsView/>
                                    </Col>
                                </CardContent>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            </Box>
        </Grid>
    </Container>
    </MKBox>

</>
        );
    }
    else {
        return null; 
    }
    
}

export default AccountPage;
