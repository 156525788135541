import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Row, Col, Container } from 'react-bootstrap';
import { Link} from 'react-router-dom';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import AdminSidePanel from './AdminSidePanel';

const AdminCreateSubscriptionProduct = () => {
  const defaultForm = {
    product_name: '',
    description: 'A map product',
    monthly_price: 1299,
    yearly_price: 10999,
    app_product_type: 'data_map_subscription',
    state_name: ''
  }
  const [formData, setFormData] = useState(defaultForm);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const authHeader = useAuthHeader()
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    let valueToUpdate = value;
    if ((name === 'monthly_price' || name === 'yearly_price') && value !== '') {
      valueToUpdate = parseInt(value, 10);
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: valueToUpdate
    }));
  }

  const handleSubmit = (e) => {
    setShowAlert(false);
    setShowErrorAlert(false);
    e.preventDefault();
    
    const submissionData = {
      ...formData,
      state_name: formData.state_name.toLowerCase().replace(/\s+/g, '_')
    };

    const config = {
      headers: {
        Authorization: authHeader
      }
    }

    axios.post(`${process.env.REACT_APP_API_URL}/subscriptions/create_subscription_products`, submissionData, config)
      .then(response => {
        setFormData(defaultForm);
        setShowAlert(true);
        setShowErrorAlert(false);
      })
      .catch(error => {
        setShowAlert(false);
        setShowErrorAlert(true);
        setAlertMessage(error.response?.data?.detail || 'An error occurred');
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <h1>Terra Grasp Admin Panel</h1>
          <h1>Create Subscription Product</h1>
          {showAlert && (
            <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
              Product successfully created!
            </Alert>
          )}
          {showErrorAlert && (
            <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
              {alertMessage}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="product_name">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product name"
                name="product_name"
                value={formData.product_name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="monthly_price">
              <Form.Label>Monthly Price (USD)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter monthly price"
                name="monthly_price"
                value={formData.monthly_price}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="yearly_price">
              <Form.Label>Yearly Price (USD)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter yearly price"
                name="yearly_price"
                value={formData.yearly_price}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="app_product_type">
              <Form.Label>Product Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product type"
                name="app_product_type"
                value={formData.app_product_type}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="state_name">
              <Form.Label>State Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state name"
                name="state_name"
                value={formData.state_name}
                onChange={handleChange}
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Col>
              <Col md={6}>
                <Link to="/admin/panel">
                  <Button variant="link">
                    Admin Panel
                  </Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md={4}>
          <AdminSidePanel />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCreateSubscriptionProduct;
