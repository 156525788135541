import React from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

function CheckoutSuccess() {
  return (
    <MKBox
      minHeight="60vh"
      width="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: '90px',
        paddingBottom: '90px'
      }}
    >
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card sx={{
              p: 4,
              textAlign: "center",
              backgroundColor: "white",
              boxShadow: "0 8px 24px 0 rgba(0,0,0,0.1)",
            }}>
              <MKBox mb={3}>
                <CheckCircleIcon sx={{ 
                  fontSize: 80, 
                  color: 'success.main',
                  mb: 2
                }} />
                <MKTypography variant="h3" color="dark" mb={1}>
                  Thank You for Your Purchase! Your transaction was successful
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={3}>
                  Check your email if you are new to TerraGrasp to set up your account. Otherwise please login
                </MKTypography>
                <MKButton
                  component={Link}
                  variant="gradient"
                  color="info"
                  to="/user/login"
                >
                  Go to My Account
                </MKButton>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default CheckoutSuccess;
