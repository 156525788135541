import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import Box from '@mui/material/Box';
import MKTypography from "components/MKTypography";
import { useCart } from "react-use-cart";
import StateImage from 'components/images/StateImages';  
import ExcelDisplay from './ExcelDisplay';
import IncomeSchema from 'components/product/IncomeSchema';
import StateDropDown from 'components/basics/StateDropDown';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/Numbers';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4'; // Add this import
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorefrontIcon from '@mui/icons-material/Storefront';

const ProductDetail = () => {
    // Color palette variables
    const greenColors = {
        lightest: '#C7F9CC',  // Lightest green
        light: '#80ED99',     // Light green
        medium: '#57CC99',    // Medium green
        darkMedium: '#38A3A5',// Dark medium teal
        darkest: '#22577A'    // Darkest blue
    };
    const colors = {
        lightest: '#caf0f8',  // Lightest blue
        light: '#90e0ef',     // Light blue
        medium: '#00b4d8',    // Medium blue
        darkMedium: '#0077b6',// Dark blue
        darkest: '#03045e'    // Darkest blue
    };
    
    const defaultState = {
        "description": "USA income by zip code",
        "id": 92,
        "image_path": "https://terragrasp-images.s3.us-west-2.amazonaws.com/fun_states/USA.webp",
        "price": null,
        "product_name": "USA",
        "product_type": "income_by_zipcode",
        "product_year": 2023,
        "quantity": 1,
        "itemTotal": 19999}
    const [productList, setProductList] = useState([]);
    const [productDetails, setProductDetails] = useState(defaultState);
    const [images, setImages] = useState(false);
    const { addItem, getItem } = useCart();
    const {
        removeItem,
        items
    } = useCart();
    const navigate = useNavigate();
    const { stateName } = useParams();
    const canonicalUrl = `https://terragrasp.com/income-by-zipcode/${stateName}`;
    // Add this useEffect hook at the beginning of the component
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (productList.length < 1) {
            axios.get(`${process.env.REACT_APP_API_URL}/products/products-type/income_by_zipcode`)
                .then(response => {
                    if (Array.isArray(response.data.data)) {
                        const sortedData = response.data.data.sort((a, b) => {
                            return a.product_name.localeCompare(b.product_name);
                        });
                        const usaProduct = sortedData.filter(item => item.product_name.toLowerCase() === 'usa');
                        const otherProducts = sortedData.filter(item => item.product_name.toLowerCase() !== 'usa');
                        const finalSortedData = [...usaProduct, ...otherProducts];
                        setProductList(finalSortedData);
                    }
                    setImages(prev => true);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        }
        if (images) {
            setImages(false);
        }
        if (stateName) {
            const product = productList.find(p => p.product_name.toLowerCase() === stateName.toLowerCase());
            if (product) {
                setProductDetails(product);
            }
        }
    }, [images, productList, stateName]);
    

    const handlePurchaseUSA =() => {
        const usaProduct = productList.find(p => p.product_name.toLowerCase() === 'usa')
        const usaID = usaProduct.id
        items.forEach((item) => {
            if (item.id !== usaID) {
                removeItem(item.id);
            }
        });
        addItem(usaProduct);

        ReactGA.event({
            category: 'Ecommerce',
            action: 'Buy All US Data',
            label: 'USA',
        });

        navigate('/user/cart');
    };

    const handleProductChange =(selectValue) => {
        const product = productList.find(p => p.product_name === selectValue);
        setProductDetails(product);
        navigate(`/income-by-zipcode/${selectValue.replace(/ /g, '_')}`, { replace: true });
    };

    const targetDataPoints = useRef(null);
    
    const DownloadSample = () => {
        const fileUrl = 'https://terragrasp-images.s3.us-west-2.amazonaws.com/sample_products/TerraGrasp_Income_by_zip_Sample_Data.xlsx';
        window.location.href = fileUrl;
    };
    
    // Add this function to generate structured data
    const generateStructuredData = () => {
        return {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": `${productDetails.product_name} Income By Zip Code`,
            "description": `Median Income data visualization by zip code for ${productDetails.product_name} product page`,
            "image": {
                "@type": "ImageObject",
                "contentUrl": StateImage(productDetails.product_name.replace(/ /g, '_')),
                "name": `${productDetails.product_name} Income by Zip Code`,
                "description": `Median Income data visualization by zip code for ${productDetails.product_name}`,
            },
            "url": `${window.location.origin}/income-by-zipcode/${productDetails.product_name.replace(/ /g, '_')}`
        };
    };
    const getStateName = () => {
        if (productDetails.product_name.toLowerCase() === 'usa') {
            return ' the USA';
        }
        return productDetails.product_name.replace(/_/g, ' ') || 'your state';
    };

    const handleScrollToSectionWithPadding = useCallback((ref) => {
        if (ref.current) {
            const topPosition = ref.current.getBoundingClientRect().top + window.scrollY;
            const padding = window.innerHeight * 0.075; // 10% of the viewport height
            window.scrollTo({
                top: topPosition - padding,
                behavior: 'smooth',
            });
        }
    }, []);

    const handleCheckoutWithProduct = () => {
        const itemInCart = getItem(productDetails.id); // Check if the item is already in the cart
        if (!itemInCart) {
            addItem(productDetails); // Add item only if it's not in the cart
        }
        ReactGA.event({
            category: 'Ecommerce',
            action: 'Checkout with Product',
            label: productDetails.product_name,
        });

        navigate('/user/cart'); // Navigate to the cart
    };

    const targetSampleData = useRef(null);
    const targetIndustryApps = useRef(null);

    const renderHeroSection = () => (
        <MKBox 
            component="section" 
            sx={{ 
                backgroundImage: 'linear-gradient(to right, rgba(0,119,182,0.9), rgba(0,180,216,0.9))',
                pt: 12,
                pb: 8,
                position: 'relative',
                mt: '-90px',
                pt: '90px'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ pr: { lg: 6 } }}>
                            <MKTypography
                                variant="h1"
                                sx={{
                                    fontSize: { 
                                        xs: '2rem',
                                        lg: '3.5rem'
                                    },
                                    fontWeight: 700,
                                    color: '#ffffff',
                                    mb: 3,
                                    lineHeight: 1.2,
                                    pt: 8,
                                }}
                            >
                                Income by Zip Code Data Report for {getStateName()}
                            </MKTypography>
                            <MKTypography
                                variant="h4"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.9)',
                                    lineHeight: 1.1,
                                    fontSize: { 
                                        xs: '1.25rem',
                                        lg: '1.5rem'
                                    }
                                }}
                            >
                                Make Data-Driven Decisions with Comprehensive Zip Code Income Reports for {getStateName()}
                            </MKTypography>
                            <Box sx={{ mt: 2, textAlign: 'left' }}>
                                <ul style={{ 
                                    listStyle: 'none', 
                                    padding: 0,
                                    margin: 0 
                                }}>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Identify Prime Locations Using Current Income Data by Zip Code
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Navigate Income Demographics Across Every US Zip Code
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Median & Mean Household Income by Zip Code
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Household Income Distribution by Income Brackets
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Retirement & Social Security Income Data 
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        98 Metrics with Margin of Error Calculations
                                    </li>
                                </ul>
                            </Box>
                            <Box sx={{ mb: 4 }}>
                                <MKButton 
                                    onClick={handleCheckoutWithProduct}
                                    variant="contained"
                                    color="success"
                                    size="large"
                                    sx={{ 
                                        py: 2,
                                        px: 4,
                                        fontSize: '1.25rem',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)'
                                        }
                                    }}
                                >
                                    Get Started for ${productDetails.price ? (productDetails.price / 100) : '199.99'}
                                </MKButton>
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                {productDetails.product_name.toLowerCase() === 'usa' ? (
                                    <span style={{ 
                                        display: 'block', 
                                        fontSize: '0.9em',
                                        color: 'rgba(255, 255, 255, 0.9)',
                                        fontStyle: 'italic' 
                                    }}>
                                        Just Need one state? Select it from the dropdown below
                                    </span>
                                ) : (
                                    <span style={{ 
                                        display: 'block', 
                                        fontSize: '0.9em',
                                        color: 'rgba(255, 255, 255, 0.9)',
                                        fontStyle: 'italic' 
                                    }}>
                                        Need more than one state? Select all the US for and save big!
                                    </span>
                                )}
                            </Box>

                            <Box sx={{ mb: 4 }}>
                                <StateDropDown
                                    onSelectButton={handleProductChange}
                                    products={productList}
                                    initialSet={stateName ? stateName.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) : ''}
                                    sx={{
                                        '& .MuiSelect-select': {
                                            color: '#ffffff',  // White text for the selected value
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(255, 255, 255, 0.5)',  // White border with transparency
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: '#ffffff',  // White color for the dropdown arrow
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#ffffff',  // Solid white border on hover
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} lg={6}>
                        <Box
                            sx={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '16px',
                                overflow: 'hidden',
                                boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                                p: 2,
                                width: '100%',
                                height: 0,
                                paddingTop: { 
                                    xs: '75%',
                                    lg: '62.6%'
                                },
                                position: 'relative',
                                mx: 'auto',
                                maxWidth: { 
                                    xs: '100%',
                                    lg: '600px'
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: 2
                                }}
                            >
                                <img 
                                    src={StateImage(productDetails["product_name"].replace(/ /g, '_'))}
                                    alt={`Income data visualization for ${getStateName()}`}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '12px'
                                    }}
                                />
                            </Box>
                        </Box>
                        
                        {productDetails.product_name.toLowerCase() !== 'usa' && (
                            <Box
                                sx={{
                                    mt: 2,
                                    py: 2,
                                    textAlign: 'center',
                                    transform: 'translateY(-10px)',
                                    opacity: 1,
                                    transition: 'all 0.3s ease',
                                }}
                            >
                                <MKButton
                                    onClick={handlePurchaseUSA}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={{ 
                                        py: 2,
                                        px: 4,
                                        fontSize: '1.25rem',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)'
                                        }
                                    }}
                                >
                                        Get All US States Data for $199 - Save Big!
                                </MKButton>
                            </Box>
                        )}
                          <Grid container spacing={3} sx={{ mt: 2 }}>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: 1,
                                        borderRadius: '8px',
                                        backgroundColor: '#fb8c00',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: colors.light,
                                            transform: 'translateY(-2px)'
                                        }
                                    }} onClick={() => handleScrollToSectionWithPadding(targetSampleData)}>
                                        <MKTypography 
                                            variant="body2"
                                            sx={{
                                                fontWeight: 500,
                                                color: '#ffffff',
                                                textAlign: 'center'
                                            }}
                                        >
                                            See a Sample
                                        </MKTypography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: 1,
                                        borderRadius: '8px',
                                        backgroundColor: colors.darkMedium,
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: colors.light,
                                            transform: 'translateY(-2px)'
                                        }
                                    }} onClick={() => handleScrollToSectionWithPadding(targetDataPoints)}>
                                        <MKTypography 
                                            variant="body2"
                                            sx={{
                                                fontWeight: 500,
                                                color: '#ffffff',
                                                textAlign: 'center'
                                            }}
                                        >
                                            See All Data Fields
                                        </MKTypography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: 1,
                                        borderRadius: '8px',
                                        backgroundColor: greenColors.darkMedium,
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: colors.light,
                                            transform: 'translateY(-2px)'
                                        }
                                    }} onClick={() => handleScrollToSectionWithPadding(targetIndustryApps)}>
                                        <MKTypography 
                                            variant="body2"
                                            sx={{
                                                fontWeight: 500,
                                                color: '#ffffff',
                                                textAlign: 'center'
                                            }}
                                        >
                                            Industry Applications
                                        </MKTypography>
                                    </Box>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );

    const renderBenefitsSection = () => (
        <MKBox 
            component="section" 
            sx={{ 
                py: { xs: 6, lg: 10 },
                background: colors.lightest,
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{ mb: { xs: 6, lg: 8 }, textAlign: 'center' }}>
                    <MKTypography
                        variant="h2"
                        sx={{
                            color: '#2c3e50',
                            fontWeight: 700,
                            mb: 2,
                            fontSize: {
                                xs: '2rem',
                                lg: '2.5rem'
                            }
                        }}
                    >
                        Why Choose Our Income Data?
                    </MKTypography>
                </Box>

                <Grid container spacing={4}>
                    {[
                        {
                            icon: <AttachMoneyIcon sx={{ fontSize: '2rem', color: colors.darkMedium }} />,
                            title: 'Comprehensive Coverage',
                            description: `Access detailed income brackets, median household income, and retirement data for every zip code in ${getStateName()}.`
                        },
                        {
                            icon: <NumbersIcon sx={{ fontSize: { xs: '1.75rem', lg: '2rem' }, color: colors.darkMedium }} />,
                            title: 'Latest Census Data',
                            description: 'Make decisions with the most current census data available, ensuring accuracy and relevance.'
                        },
                        {
                            icon: <PercentIcon sx={{ fontSize: { xs: '1.75rem', lg: '2rem' }, color: colors.darkMedium }} />,
                            title: 'Ready-to-Use Format',
                            description: 'Download instantly in Excel format with organized, clean data ready for analysis and integration.'
                        }
                    ].map((benefit, index) => (
                        <Grid item xs={12} lg={4} key={index}>
                            <Box
                                sx={{
                                    height: '100%',
                                    p: { xs: 1, lg: 4 },
                                    borderRadius: '16px',
                                    background: 'white',
                                    boxShadow: '0 0 20px rgba(0,0,0,0.05)',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: '0 10px 30px rgba(0,0,0,0.1)'
                                    }
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        mb: 1
                                    }}
                                >
                                    {benefit.icon}
                                    <MKTypography 
                                        variant="h4" 
                                        sx={{ 
                                            color: '#2c3e50',
                                            fontWeight: 500,
                                            fontSize: {
                                                xs: '1.5rem',
                                                lg: '1.75rem'
                                            }
                                        }}
                                    >
                                        {benefit.title}
                                    </MKTypography>
                                </Box>
                                
                                <MKTypography 
                                    variant="body1" 
                                    sx={{ 
                                        color: '#2c3e50',
                                        lineHeight: 1.6,
                                        fontSize: {
                                            xs: '0.9rem',
                                            lg: '1rem'
                                        }
                                    }}
                                >
                                    {benefit.description}
                                </MKTypography>
                            </Box>
                        </Grid>
                    ))}
                    {/* Call to Action */}
                    <Grid item xs={12}>
                        <Box sx={{ 
                            p: { xs: 2, lg: 3 }, 
                            mt: { xs: 2, lg: 4 },
                            textAlign: 'center',
                            background: 'white',
                            borderRadius: '16px',
                            boxShadow: `0 4px 20px ${colors.darkest}1A`,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <MKTypography 
                                variant="h3" 
                                mb={3} 
                                sx={{ 
                                    color: colors.darkest,
                                    fontSize: {
                                        xs: '1.75rem',
                                        lg: '2.25rem'
                                    }
                                }}
                            >
                                Why Purchase From us? 
                            </MKTypography>
                            <MKTypography 
                                variant="body1" 
                                mb={2} 
                                sx={{ 
                                    color: colors.darkest,
                                    maxWidth: '100vh',
                                    lineHeight: 1.6,
                                    fontSize: {
                                        xs: '0.9rem',
                                        lg: '1rem'
                                    }
                                }}
                            >
                                Save valuable time and resources by letting us handle the complex census data extraction process. 
                                Our team has already done the heavy lifting - cleaning, organizing, and formatting the data from 
                                multiple census tables. What would typically take days to gather and process is available instantly, 
                                allowing you to focus on using the insights rather than collecting them.
                            </MKTypography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );

    const renderPricingSection = () => (
        <MKBox component="section" py={6} sx={{ background: 'white' }}>
            <Container maxWidth="lg">
                <MKTypography variant="h2" textAlign="center" mb={5}>
                    Choose Your Data Package
                </MKTypography>
                
                <Grid container spacing={4} justifyContent="center">
                    {/* State Package */}
                    <Grid item xs={12} lg={5}>
                        <Box sx={{ 
                            p: { xs: 3, lg: 4 },
                            border: '1px solid',
                            borderColor: 'grey.300',
                            borderRadius: '16px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            '&:hover': {
                                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
                                transform: 'translateY(-4px)',
                                transition: 'all 0.3s ease'
                            }
                        }}>
                            <MKTypography 
                                variant="h3" 
                                mb={2}
                                sx={{
                                    fontSize: {
                                        xs: '1.75rem',
                                        lg: '2.25rem'
                                    }
                                }}
                            >
                                {getStateName()} Package
                            </MKTypography>
                            <MKTypography 
                                variant="h2" 
                                color="success" 
                                mb={3}
                                sx={{
                                    fontSize: {
                                        xs: '2rem',
                                        lg: '2.5rem'
                                    }
                                }}
                            >
                                ${productDetails.price ? (productDetails.price / 100) : '49'}
                            </MKTypography>
                            <Box sx={{ mb: 3 }}>
                                <MKTypography variant="body1" component="div">
                                    <ul style={{ listStyle: 'none', padding: 0 }}>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                            Complete {getStateName()} zip code data
                                        </li>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                            Income brackets & median income
                                        </li>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                            Retirement income data
                                        </li>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                            Instant Excel download
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Box>
                            <Box sx={{ mt: 'auto' }}>
                                <MKButton 
                                    onClick={handleCheckoutWithProduct}
                                    variant="gradient" 
                                    color="success" 
                                    fullWidth
                                    sx={{ fontSize: '1.1rem', py: 1.5 }}
                                >
                                    Get {getStateName()} Data
                                </MKButton>
                            </Box>
                        </Box>
                    </Grid>

                    {/* USA Package */}
                    <Grid item xs={12} lg={5}>
                        <Box sx={{ 
                            p: { xs: 3, lg: 4 },
                            border: '2px solid',
                            borderColor: 'primary.main',
                            borderRadius: '16px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            '&:hover': {
                                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
                                transform: 'translateY(-4px)',
                                transition: 'all 0.3s ease'
                            }
                        }}>
                            <Box sx={{
                                position: 'absolute',
                                top: -12,
                                right: { xs: 16, lg: 24 },
                                background: '#e91e63',
                                color: 'white',
                                px: 2,
                                py: 0.5,
                                borderRadius: '12px',
                                fontWeight: 'bold',
                                boxShadow: '0 2px 8px rgba(233, 30, 99, 0.3)',
                                zIndex: 1
                            }}>
                                Best Value
                            </Box>
                            <MKTypography 
                                variant="h3" 
                                mb={2}
                                sx={{
                                    fontSize: {
                                        xs: '1.75rem',
                                        lg: '2.25rem'
                                    }
                                }}
                            >
                                Complete USA Package
                            </MKTypography>
                            <MKTypography 
                                variant="h2" 
                                color="primary" 
                                mb={3}
                                sx={{
                                    fontSize: {
                                        xs: '2rem',
                                        lg: '2.5rem'
                                    }
                                }}
                            >
                                $199
                            </MKTypography>
                            <Box sx={{ mb: 3 }}>
                                <MKTypography variant="body1" component="div">
                                    <ul style={{ listStyle: 'none', padding: 0 }}>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                            All 50 states included
                                        </li>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                            Complete nationwide coverage
                                        </li>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                            All income metrics included
                                        </li>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                            Bulk discount savings
                                        </li>
                                        <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                            Priority support
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Box>
                            <Box sx={{ mt: 'auto' }}>
                                <MKButton 
                                    onClick={handlePurchaseUSA}
                                    variant="gradient" 
                                    color="primary" 
                                    fullWidth
                                    sx={{ fontSize: '1.1rem', py: 1.5 }}
                                >
                                    Get Complete USA Data
                                </MKButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* Money-back Guarantee */}
                <Box sx={{ 
                    mt: { xs: 4, lg: 6 },
                    p: { xs: 2, lg: 3 },
                    textAlign: 'center',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    borderRadius: '8px',
                    maxWidth: '600px',
                    mx: 'auto'
                }}>
                    <MKTypography 
                        variant="h5" 
                        mb={2}
                        sx={{
                            fontSize: {
                                xs: '1.25rem',
                                lg: '1.5rem'
                            }
                        }}
                    >
                        100% Money-Back Guarantee
                    </MKTypography>
                    <MKTypography 
                        variant="body1" 
                        color="text.secondary"
                        sx={{
                            fontSize: {
                                xs: '0.9rem',
                                lg: '1rem'
                            }
                        }}
                    >
                        Not satisfied with your purchase? We offer a full refund, no questions asked. 
                        Your satisfaction is our top priority.
                    </MKTypography>
                </Box>
                <Box sx={{ 
                    textAlign: 'center', 
                    mt: { xs: 3, lg: 4 },
                    mb: 2 
                }}>
                    <MKTypography
                        variant="body1"
                        onClick={handleMapNavigation}
                        sx={{
                            color: colors.darkMedium,
                            textDecoration: 'none',
                            display: 'inline-flex',
                            alignItems: 'center',
                            transition: 'color 0.3s',
                            cursor: 'pointer',
                            fontSize: {
                                xs: '0.9rem',
                                lg: '1rem'
                            },
                            '&:hover': {
                                color: colors.darkest
                            }
                        }}
                    >
                        Want to explore the data visually? Check out our Interactive Income Maps →
                    </MKTypography>
                </Box>
            </Container>
        </MKBox>
    );

    const renderSampleDataSection = () => (
        <MKBox component="section" py={6} sx={{ background: '#403d39' }} ref={targetSampleData}>
            <Container maxWidth="lg">
                {/* Sample Data Header */}
                <MKTypography 
                    variant="h2" 
                    textAlign="center" 
                    mb={4} 
                    color="white"
                    sx={{
                        fontSize: {
                            xs: '2rem',
                            lg: '2.5rem'
                        }
                    }}
                >
                    Preview Your Data Package
                </MKTypography>
                
                <Grid container spacing={4} justifyContent="center">
                    {/* Left Column - Description - Changed from md to lg */}
                    <Grid item xs={12} lg={6}>
                        <MKBox p={{ xs: 2, lg: 3 }} sx={{ color: 'white' }}>
                            <MKTypography 
                                variant="h4" 
                                mb={3} 
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '1.5rem',
                                        lg: '2rem'
                                    }
                                }}
                            >
                                What's Included in Your Download?
                            </MKTypography>
                            <MKTypography 
                                variant="body1" 
                                mb={4} 
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '0.9rem',
                                        lg: '1rem'
                                    }
                                }}
                            >
                                Get a comprehensive Excel file containing 3 sheets of detailed income data organized by zip code.
                                Each metric includes both precise estimates and margin of error calculations, ensuring maximum accuracy. 
                                Download our free sample to preview the full dataset.
                            </MKTypography>
                            
                            {/* Download Sample Button */}
                            <MKButton 
                                onClick={DownloadSample} 
                                variant="gradient" 
                                color="warning" 
                                size="large"
                                sx={{ 
                                    fontSize: { xs: '1rem', lg: '1.1rem' },
                                    width: '100%',
                                    mb: 3,
                                    py: { xs: 1.5, lg: 2 }
                                }}
                            >
                                Download Free Sample Data
                            </MKButton>
                            
                            {/* Key Features List */}
                            <Box sx={{ mt: 3 }}>
                                <MKTypography 
                                    variant="h5" 
                                    mb={2} 
                                    color="white"
                                    sx={{
                                        fontSize: {
                                            xs: '1.25rem',
                                            lg: '1.5rem'
                                        }
                                    }}
                                >
                                    Key Data Points Include:
                                </MKTypography>
                                <ul style={{ 
                                    color: 'white', 
                                    paddingLeft: '20px',
                                    fontSize: { xs: '0.9rem', lg: '1rem' }
                                }}>
                                    <li>Median Household Income</li>
                                    <li>Income Brackets Distribution</li>
                                    <li>Retirement Income Statistics</li>
                                    <li>Age-Specific Income Data</li>
                                    <li>Margin of Error Values</li>
                                </ul>
                            </Box>
                        </MKBox>
                    </Grid>

                    {/* Right Column - Excel Preview - Changed from md to lg */}
                    <Grid item xs={12} lg={6}>
                        <MKBox 
                            sx={{ 
                                background: 'white',
                                borderRadius: '16px',
                                overflow: 'hidden',
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                                mx: 'auto',
                                maxWidth: { xs: '100%', lg: '600px' }
                            }}
                        >
                            <MKTypography 
                                variant="h5" 
                                p={2} 
                                textAlign="center"
                                sx={{
                                    fontSize: {
                                        xs: '1.25rem',
                                        lg: '1.5rem'
                                    }
                                }}
                            >
                                Sample Data Preview
                            </MKTypography>
                            <ExcelDisplay />
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );

    const renderIndustryApplications = () => (
        <MKBox component="section" py={6} sx={{ background: 'white' }} ref={targetIndustryApps}>
            <Container maxWidth="lg">
                <MKTypography 
                    variant="h2" 
                    textAlign="center" 
                    mb={5}
                    sx={{
                        color: colors.darkest,
                        fontWeight: 700,
                        fontSize: {
                            xs: '2rem',
                            lg: '2.5rem'
                        }
                    }}
                >
                    Industry Applications
                </MKTypography>

                <Grid container spacing={4}>
                    {/* Real Estate */}
                    <Grid item xs={12} lg={4}>
                        <Box sx={{ 
                            p: { xs: 3, lg: 4 },
                            height: '100%',
                            borderRadius: '16px',
                            backgroundColor: colors.lightest,
                            color: 'white',
                            boxShadow: `0 8px 24px ${colors.lightest}26`,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: `0 12px 30px ${colors.lightest}40`
                            }
                        }}>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                mb: 3,
                                gap: 1
                            }}>
                                <MapsHomeWorkIcon sx={{ 
                                    fontSize: { xs: '1.75rem', lg: '2rem' },
                                    color: '#000000'
                                }} />
                                <MKTypography 
                                    variant="h4" 
                                    color="black"
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            lg: '1.75rem'
                                        }
                                    }}
                                >
                                    Real Estate
                                </MKTypography>
                            </Box>
                            <Box component="ul" sx={{ 
                                listStyle: 'none', 
                                p: 0, 
                                m: 0,
                                '& li': {
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    color: '#000000',
                                    fontWeight: 500,
                                    '&:before': {
                                        content: '"•"',
                                        mr: 2,
                                        color: '#000000'
                                    }
                                }
                            }}>
                                <li>Identify prime locations for development</li>
                                <li>Target luxury markets effectively</li>
                                <li>Analyze retirement community potential</li>
                                <li>Optimize property valuations</li>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Financial Services */}
                    <Grid item xs={12} lg={4}>
                        <Box sx={{ 
                            p: { xs: 3, lg: 4 },
                            height: '100%',
                            borderRadius: '16px',
                            backgroundColor: colors.medium,
                            color: 'white',
                            boxShadow: `0 8px 24px ${colors.medium}26`,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: `0 12px 30px ${colors.medium}40`
                            }
                        }}>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                mb: 3,
                                gap: 1
                            }}>
                                <AccountBalanceIcon sx={{ 
                                    fontSize: { xs: '1.75rem', lg: '2rem' },
                                    color: '#FFFFFF'
                                }} />
                                <MKTypography 
                                    variant="h4" 
                                    color="white"
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            lg: '1.75rem'
                                        }
                                    }}
                                >
                                    Financial Services
                                </MKTypography>
                            </Box>
                            <Box component="ul" sx={{ 
                                listStyle: 'none', 
                                p: 0, 
                                m: 0,
                                '& li': {
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    color: '#FFFFFF',
                                    fontWeight: 500,
                                    '&:before': {
                                        content: '"•"',
                                        mr: 2,
                                        color: '#FFFFFF'
                                    }
                                }
                            }}>
                                <li>Target high-net-worth areas</li>
                                <li>Develop retirement products</li>
                                <li>Optimize branch locations</li>
                                <li>Tailor investment offerings</li>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Marketing */}
                    <Grid item xs={12} lg={4}>
                        <Box sx={{ 
                            p: { xs: 3, lg: 4 },
                            height: '100%',
                            borderRadius: '16px',
                            backgroundColor: colors.lightest,
                            color: 'white',
                            boxShadow: `0 8px 24px ${colors.lightest}26`,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: `0 12px 30px ${colors.lightest}40`
                            }
                        }}>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                mb: 3,
                                gap: 1
                            }}>
                                <StorefrontIcon sx={{ 
                                    fontSize: { xs: '1.75rem', lg: '2rem' },
                                    color: '#000000'
                                }} />
                                <MKTypography 
                                    variant="h4" 
                                    color="black"
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            lg: '1.75rem'
                                        }
                                    }}
                                >
                                    Marketing
                                </MKTypography>
                            </Box>
                            <Box component="ul" sx={{ 
                                listStyle: 'none', 
                                p: 0, 
                                m: 0,
                                '& li': {
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    color: '#000000',
                                    fontWeight: 500,
                                    '&:before': {
                                        content: '"•"',
                                        mr: 2,
                                        color: '#000000'
                                    }
                                }
                            }}>
                                <li>Target campaigns by income</li>
                                <li>Optimize ad spend</li>
                                <li>Identify market opportunities</li>
                                <li>Segment audiences effectively</li>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* Call to Action */}
                <Grid item xs={12}>
                    <Box sx={{ 
                        mt: { xs: 6, lg: 8 },
                        p: { xs: 4, lg: 6 },
                        textAlign: 'center',
                        background: 'white',
                        borderRadius: '16px',
                        boxShadow: `0 4px 20px ${colors.darkest}1A`,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <MKTypography 
                            variant="h3" 
                            mb={4} 
                            sx={{ 
                                color: colors.darkest,
                                fontSize: {
                                    xs: '1.75rem',
                                    lg: '2.25rem'
                                }
                            }}
                        >
                            Ready to Transform Your Business?
                        </MKTypography>
                        <MKButton 
                            onClick={handleCheckoutWithProduct}
                            variant="gradient" 
                            color="info" 
                            size="large"
                            sx={{ 
                                fontSize: { xs: '1rem', lg: '1.1rem' },
                                px: { xs: 4, lg: 6 },
                                py: { xs: 1.5, lg: 2 },
                                backgroundColor: colors.darkest,
                                boxShadow: `0 4px 10px ${colors.darkest}33`,
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: `0 6px 15px ${colors.darkest}4D`,
                                    backgroundColor: colors.darkMedium
                                }
                            }}
                        >
                            Get Started Now
                        </MKButton>
                    </Box>
                </Grid>
            </Container>
        </MKBox>
    );

    const handleMapNavigation = () => {
        navigate('/income-map');
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Helmet>
                <title>{`${getStateName()} Income By Zip Code`}</title>
                <meta name="description" content={`Median Income data visualization by zip code for ${getStateName()}`} />
                <script type="application/ld+json">
                    {JSON.stringify(generateStructuredData())}
                </script>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>

            {/* Hero Section */}
            {renderHeroSection()}

            {/* Benefits Section */}
            {renderBenefitsSection()}

            {/* Pricing Section */}
            {renderPricingSection()}

            {/* Sample Data Section */}
            {renderSampleDataSection()}

            {/* Industry Applications */}
            {renderIndustryApplications()}

            {/* Schema Data Section - Keep if still needed */}
            <MKBox component="section" py={1} sx={{ textAlign: "center", background: '#403d39', mx: "auto", color: '#ffffff' }}>
                <Grid container flexDirection="column" alignItems="center"
                    sx={{ textAlign: "center" }}
                >
                    <div style={{ textAlign: 'Center', maxWidth: '70%', marginBottom: '2vh'}} ref={targetDataPoints}>
                        <MKTypography variant="h3" mb={2} color='#ffffff'>
                            See All Data Fields
                        </MKTypography>
                        <IncomeSchema />
                    </div>
                </Grid>
            </MKBox>

            {/* Important Details Section */}
            <MKBox component="section" py={2} sx={{ textAlign: "center", mx: "auto" }}>
                <Container>
                    <Grid container flexDirection="column" alignItems="center">
                        <Box sx={{ maxWidth: '70%', my: 4 }}>
                            <MKTypography variant="h3" mb={3}>
                                Important Data Details
                            </MKTypography>
                            <MKTypography variant="body1" mb={3}>
                                When you purchase data for {getStateName()}, you may notice that some zip codes are missing data for certain metrics or entirely.
                                This can be due to various factors such as data suppression for privacy protection, non-response from individuals, or data collection issues.
                                These missing values originate directly from the census and are unavailable to the public.
                            </MKTypography>
                            <MKTypography variant="body1">
                                The data is sourced from the 2023 publicly available U.S. Census data, which is the most recent dataset available from the census bureau.
                                Invest in your {getStateName()}-specific income data today and empower your decisions with accuracy and comprehensive insights.
                            </MKTypography>
                        </Box>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
};
export default ProductDetail;

