import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import StateDropDown from 'components/basics/StateDropDown';

function SubscriptionPage() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState('year');
  const [displayPrice, setDisplayPrice] = useState("Select Plan");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const authHeader = useAuthHeader();
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions/subscription_products`,
          {
            headers: {
              Authorization: authHeader
            }
          }
        );
        // Transform the data while preserving original data for prices
        const transformedSubscriptions = response.data.map(sub => ({
          product_name: sub.subscription_product_name,
          _originalData: sub  // Store the original data for price calculations
        }));
        
        setSubscriptions(transformedSubscriptions);
        if (transformedSubscriptions.length > 0) {
          setSelectedSubscription(response.data[0]);
          updateDisplayContent(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setError("Failed to load subscription options");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptions();
  }, [authHeader]);

  useEffect(() => {
    if (selectedSubscription) {
      updateDisplayContent(selectedSubscription);
    }
  }, [selectedInterval]);

  const updateDisplayContent = (subscription) => {
    if (subscription) {
      const price = subscription.prices.find(p => p.interval === selectedInterval);
      if (price) {
        setDisplayPrice(`$${(price.amount/100).toFixed(2)}`);
      }
    }
  };

  const cardStyles = {
    p: 4,
    height: "100%",
    minHeight: "600px",
    backgroundColor: "info.main",
    color: "white",
    boxShadow: "0 8px 24px 0 rgba(0,0,0,0.2)",
    transform: "scale(1.02)",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  };

  const calculateYearlyDiscount = () => {
    if (selectedSubscription) {
      const monthlyPrice = selectedSubscription.prices.find(p => p.interval === 'month')?.amount || 0;
      const yearlyPrice = selectedSubscription.prices.find(p => p.interval === 'year')?.amount || 0;
      
      if (monthlyPrice && yearlyPrice) {
        const yearlyTotal = monthlyPrice * 12;
        const discount = ((yearlyTotal - yearlyPrice) / yearlyTotal) * 100;
        // Round to nearest 10
        return Math.round(discount / 10) * 10;
      }
    }
    return 0;
  };

  const calculateActualSavings = (subscription) => {
    if (!subscription) return null;
    
    const monthlyPrice = subscription.prices.find(p => p.interval === 'month')?.amount || 0;
    const yearlyPrice = subscription.prices.find(p => p.interval === 'year')?.amount || 0;
    
    if (monthlyPrice && yearlyPrice) {
      const yearlyTotal = (monthlyPrice * 12);
      const savings = ((yearlyTotal - yearlyPrice) / yearlyTotal) * 100;
      return Math.round(savings) + '%';
    }
    return null;
  };

  const handleSubscribe = async (forcedInterval) => {
    if (selectedSubscription) {
      setIsCheckoutLoading(true);
      try {
        // Use the forced interval if provided, otherwise use the selected interval
        const priceInterval = forcedInterval || selectedInterval;
        const selectedPrice = selectedSubscription.prices.find(p => p.interval === priceInterval);
        
        if (!selectedPrice) {
          throw new Error('No price found for selected interval');
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/subscriptions/create-checkout-session`,
          {
            product_id: selectedSubscription.id,
            price_id: selectedPrice.id
          }
        );
        
        window.location.href = response.data.checkout_url;
      } catch (error) {
        console.error('Error creating checkout session:', error);
      } finally {
        setIsCheckoutLoading(false);
      }
    } else {
      console.log('No subscription selected');
    }
  };

  const renderSubscriptionCard = (title, price, interval, features, isHighlighted = false, isAnnual = false, showStateDropdown = false) => {
    // Calculate the display price based on the subscription
    const getDisplayPrice = () => {
      // Special case for free tier
      if (price === "0" || price === 0) {
        return "Free";
      }

      if (!selectedSubscription) return price;
      const relevantPrice = selectedSubscription.prices.find(p => 
        p.interval === (isAnnual ? 'year' : 'month')
      );
      
      if (relevantPrice) {
        // For annual plans, show the monthly equivalent
        if (isAnnual) {
          return ((relevantPrice.amount / 12) / 100).toFixed(2);
        }
        // For monthly plans, show the direct monthly price
        return (relevantPrice.amount / 100).toFixed(2);
      }
      return price;
    };

    // Get the annual total for display if it's an annual plan
    const getAnnualTotal = () => {
      if (isAnnual && selectedSubscription) {
        const yearlyPrice = selectedSubscription.prices.find(p => p.interval === 'year');
        if (yearlyPrice) {
          return `$${(yearlyPrice.amount / 100).toFixed(0)} yearly`;
        }
      }
      return null;
    };

    // Calculate savings for annual plan
    const savings = isAnnual ? calculateActualSavings(selectedSubscription) : null;
    const annualTotal = getAnnualTotal();

    

    return (
      <Grid item xs={12} lg={4}>
        <Card sx={{
          ...cardStyles,
          backgroundColor: isHighlighted ? "info.main" : "white",
          color: isHighlighted ? "white" : "dark",
          transform: isHighlighted ? "scale(1.02)" : "scale(1)",
          position: 'relative',
          minHeight: isHighlighted ? "640px" : "600px",
          marginTop: isHighlighted ? "-20px" : "0",
          marginBottom: isHighlighted ? "20px" : "0",
          display: 'flex',
          flexDirection: 'column',
        }}>
          <MKBox 
            textAlign="left" 
            mb={3}
            sx={{ flex: 1 }}
          >
            <MKTypography variant="h4" color={isHighlighted ? "white" : "dark"} mb={1} fontWeight="bold" textAlign="center">
              {title}
            </MKTypography>
            <MKBox 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                gap: 2,
                mb: 1
              }}
            >
              <MKTypography variant="h2" color={isHighlighted ? "white" : "dark"} textAlign="center">
                {getDisplayPrice() === "Free" ? (
                  "Free"
                ) : (
                  <>
                    ${getDisplayPrice()}
                    <MKTypography variant="button" color={isHighlighted ? "white" : "dark"} verticalAlign="super">
                      /month
                    </MKTypography>
                  </>
                )}
              </MKTypography>
              {savings && (
                <MKBox
                  sx={{
                    backgroundColor: '#4CAF50',  // Light green color
                    borderRadius: '4px',
                    py: 1,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                  }}
                >
                  <MKTypography
                    variant="h6"
                    color="white"
                    fontWeight="bold"
                  >
                    SAVE {savings}
                  </MKTypography>
                </MKBox>
              )}
            </MKBox>
            {annualTotal && (
              <MKTypography 
                variant="body2" 
                color={isHighlighted ? "white" : "dark"} 
                sx={{ opacity: 0.8 }}
                textAlign="center"
              >
                {annualTotal}
              </MKTypography>
            )}
            {features.map((feature, index) => (
              <MKTypography
                key={index}
                variant="body2"
                color={isHighlighted ? "white" : "dark"}
                mb={2}
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                sx={{ 
                  width: '100%',
                  pl: 2,
                  pr: 2,
                  fontSize: '1.1rem',
                  lineHeight: '1.5',
                }}
              >
                <Icon sx={{ 
                  color: "warning.main", 
                  mr: 1,
                  mt: 0.5,
                  fontSize: '1.1rem'
                }}>
                  check
                </Icon>
                <Box sx={{ flex: 1 }}>
                  {feature}
                </Box>
              </MKTypography>
            ))}
          </MKBox>

          <MKBox 
            sx={{ 
              mt: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            {showStateDropdown && (
              <MKBox>
                {isLoading ? (
                  <CircularProgress />
                ) : error ? (
                  <MKTypography color="error">
                    {error}
                  </MKTypography>
                ) : (
                  <>
                    <MKTypography 
                      variant="body2" 
                      mb={1} 
                      color={isHighlighted ? "white" : "dark"}
                      textAlign="center"
                      sx={{
                        fontSize: '0.9rem',
                        opacity: 0.9
                      }}
                    >
                      Select your state
                    </MKTypography>
                    <MKBox sx={{ 
                      bgcolor: '#FFFFFF', 
                      p: 1, 
                      borderRadius: 1,
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                      minHeight: '56px',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      <StateDropDown
                        showPrice={false}
                        products={subscriptions}
                        onSelectButton={(selectedProduct) => {
                          const selected = subscriptions.find(sub => sub.product_name === selectedProduct);
                          setSelectedSubscription(selected._originalData);
                          updateDisplayContent(selected._originalData);
                        }}
                        initialSet={selectedSubscription?.subscription_product_name}
                      />
                    </MKBox>
                  </>
                )}
              </MKBox>
            )}

            {!showStateDropdown && (
              <MKBox sx={{ minHeight: '56px' }} />
            )}

            <MKButton
              variant="gradient"
              size="large"
              fullWidth
              color="warning"
              onClick={() => handleSubscribe(isAnnual ? 'year' : 'month')}
              disabled={showStateDropdown && !selectedSubscription}
            >
              Get Started
            </MKButton>
          </MKBox>
        </Card>
      </Grid>
    );
  };

  return (
    <MKBox
      minHeight="100vh"
      width="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: '20px',
        paddingBottom: '90px'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} textAlign="center" mb={6}>
            <MKTypography variant="overline" color="error">PRICING</MKTypography>
            <MKTypography
              variant="h1"
              color="dark"
              mb={3}
            >
              Get Started
            </MKTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3} justifyContent="center">
          {/* Free Plan */}
          {renderSubscriptionCard(
            "Free",
            "0",
            "month",
            [
              "View 9 basic metrics at the tract level",
              "Access to social, demographic, economic, and housing data",
              "Compare data across geographic levels",
              "Basic data visualization tools"
            ],
            false,
            false,
            false
          )}

          {/* Monthly Plan */}
          {renderSubscriptionCard(
            "MONTHLY PASS",
            "19.99",
            "month",
            [
              "Access 500+ Comprehensive metrics",
              "Detailed tract-level analysis of Economic, Housing, Demographic. and Social data",
              "Interactive map visualization with custom color gradients",
              "Compare metrics across different geographic levels"
            ],
            true,
            false,
            true
          )}

          {/* Annual Plan */}
          {renderSubscriptionCard(
            "ANNUAL PASS",
            "179.99",
            "month",
            [
              "All features of Monthly Pass",
              "Advanced data filtering and comparison tools",
              "Unlimited tract-level data views",
              "Priority access to new metrics and features",
              `Save ${calculateYearlyDiscount()}% with annual billing`
            ],
            false,
            true,
            true
          )}
        </Grid>

        {/* Keep your existing money-back guarantee text */}
        <Grid container justifyContent="center" mt={6}>
          <Grid item xs={12} md={8} textAlign="center">
            <MKTypography variant="body2" color="text">
              All plans include a 30-day money-back guarantee. Need help choosing? <MKButton variant="text" color="info" component="a" href="/contact-us">Contact us</MKButton>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SubscriptionPage;
