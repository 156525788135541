import React, { useEffect,} from 'react';
import { useNavigate } from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { useCart } from "react-use-cart";


function CheckoutSuccessRedirect() {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
    const { emptyCart } = useCart();
    useEffect(() => {
        emptyCart();
        if (!isAuthenticated) {
            navigate("/user/checkout-success-create-account");
        }
        else {
            navigate("/account");

        }
    }, [isAuthenticated, navigate,emptyCart]);
}

export default CheckoutSuccessRedirect;

