import React, { useState } from 'react';
import { Container, Grid, Box } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import SubscriptionPage from "components/Subscriptions/SubscriptionPage";
import StateMap from 'components/maps/StateMap';
import demoGif from '../../assets/gifs/demo_income_newyork_map.gif';
import staticMap from '../../assets/images/map_image_default.png';
import LandingMetricDisplay from './LandingMetricDisplay';

const BuyIncomeMap = () => {
    // Color palette
    const colors = {
        primary: '#0077b6',
        secondary: '#48cae4',
        accent: '#00b4d8',
        light: '#90e0ef',
        lightest: '#caf0f8',
        dark: '#03045e'
    };

    // Group metrics by their hierarchy type
    const metricsByType = {
        DP02: [
            {
                id: "DP02_0088",
                label: "Total Population",
                item_mapping: ["DP02_0088E", "DP02_0088M", "DP02_0088PE", "DP02_0088PM"]
            },
            {
                id: "DP02_0090",
                label: "Population Born In United States",
                item_mapping: ["DP02_0090E", "DP02_0090M", "DP02_0090PE", "DP02_0090PM"]
            },
            {
                id: "DP02_0092",
                label: "Population Born In Different State",
                item_mapping: ["DP02_0092E", "DP02_0092M", "DP02_0092PE", "DP02_0092PM"]
            }
        ],
        DP03: [
            {
                id: "DP03_0002",
                label: "Population 16 Years And Over Employment rate",
                item_mapping: ["DP03_0002E", "DP03_0002M", "DP03_0002PE", "DP03_0002PM"]
            },
            {
                id: "DP03_0068",
                label: "Total Households With Retirement Income",
                item_mapping: ["DP03_0068E", "DP03_0068M", "DP03_0068PE", "DP03_0068PM"]
            }
        ],
        DP04: [
            {
                id: "DP04_0089",
                label: "Median (dollars) Value Of Owner-occupied Housing Units",
                item_mapping: ["DP04_0089E", "DP04_0089M", "DP04_0089PE", "DP04_0089PM"]
            },
            {
                id: "DP04_0001",
                label: "Total housing units",
                item_mapping: ["DP04_0001E", "DP04_0001M", "DP04_0001PE", "DP04_0001PM"]
            }
        ],
        DP05: [
            {
                id: "DP05_0089",
                label: "Citizen, 18 And Over Population",
                item_mapping: ["DP05_0089E", "DP05_0089M", "DP05_0089PE", "DP05_0089PM"]
            },
            {
                id: "DP05_0001",
                label: "Total population",
                item_mapping: ["DP05_0001E", "DP05_0001M", "DP05_0001PE", "DP05_0001PM"]
            }
        ]
    };

    const [currentProfile, setCurrentProfile] = useState('DP03');

    const renderHeroSection = () => (
        <MKBox
            minHeight="75vh"
            width="100%"
            sx={{
                backgroundImage: 'linear-gradient(to right, rgba(0,119,182,0.9), rgba(0,180,216,0.9))',
                display: "flex",
                alignItems: "center",
                position: 'relative',
                mt: '-90px',
                pt: '90px'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <MKBox p={{ xs: 2, lg: 6 }}>
                            <MKTypography
                                variant="h1"
                                color="white"
                                mb={3}
                                sx={{
                                    fontSize: {
                                        xs: '2rem',
                                        md: '3.5rem'
                                    },
                                    fontWeight: 700,
                                    wordBreak: 'break-word'
                                }}
                            >
                                Interactive Income Maps For
                                <br />
                                Comprehensive Data Visualization
                            </MKTypography>
                            <MKTypography
                                variant="body1"
                                color="white"
                                opacity={0.8}
                                mb={6}
                                pr={{ xs: 0, md: 8 }}
                                sx={{
                                    fontSize: {
                                        xs: '0.9rem',
                                        md: '1.25rem'
                                    }
                                }}
                            >
                                Explore 550+ metrics across <strong>Economic</strong>, <strong>Demographic</strong>, <strong>Social</strong>, and <strong>Housing</strong> data. 
                                Make informed decisions with our interactive maps and detailed analytics at the tract level.
                            </MKTypography>
                            <MKButton
                                variant="contained"
                                color="warning"
                                size="large"
                                onClick={() => {
                                    document.getElementById('pricing').scrollIntoView({ 
                                        behavior: 'smooth',
                                        block: 'start'
                                    });
                                }}
                                sx={{
                                    px: 6,
                                    py: 2,
                                    fontSize: '1.1rem'
                                }}
                            >
                                Start Mapping Now
                            </MKButton>
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox
                            sx={{
                                position: 'relative',
                                width: '100%',
                                paddingTop: { xs: '50%', md: '62.6%' },
                                overflow: 'hidden',
                                borderRadius: '8px',
                                boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
                                mx: 'auto',
                                maxWidth: { xs: '100%', md: '600px' },
                                '& picture': {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%'
                                },
                                '& img': {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    backgroundColor: colors.lightest
                                }
                            }}
                        >
                            <picture>
                                <source 
                                    srcSet={demoGif} 
                                    type="image/gif" 
                                />
                                <img 
                                    src={staticMap}
                                    alt="Interactive Income Map Preview"
                                />
                            </picture>
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );

    const renderDemoSection = () => (
        <MKBox
            component="section"
            sx={{ 
                background: 'white',
                position: 'relative',
                overflow: 'visible',
                py: { xs: 4, sm: 6, md: 8 }
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="center" mb={4}>
                    <Grid item xs={12} textAlign="center">
                        <MKTypography 
                            variant="h2" 
                            mb={2}
                            sx={{
                                fontSize: {
                                    xs: '1.75rem',
                                    sm: '2rem',
                                    md: '2.5rem'
                                }
                            }}
                        >
                            Explore Our Data Platform
                        </MKTypography>
                        <MKTypography 
                            variant="body1" 
                            color="text" 
                            mb={4}
                            sx={{
                                px: { xs: 2, sm: 4, md: 0 },
                                fontSize: {
                                    xs: '0.9rem',
                                    sm: '1rem',
                                    md: '1.1rem'
                                }
                            }}
                        >
                            Explore 500+ metrics including average retirement income, social security income, 
                            mean and median household income across all 50 US states. Our powerful mapping 
                            tools help visualize comprehensive economic, social, demographic, and housing data at the census tract level.
                        </MKTypography>
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        position: 'relative',
                        height: { xs: '50vh', sm: '60vh', md: '70vh' },
                        minHeight: { xs: '400px', sm: '500px' },
                        maxHeight: { xs: '500px', sm: '600px', md: '700px' },
                        borderRadius: '16px',
                        overflow: 'hidden',
                        boxShadow: '20px 20px 40px rgba(0,0,0,0.1)',
                        mb: 2,
                    }}
                >
                    <StateMap 
                        initialState="Arizona"
                        initialMetrics={metricsByType[currentProfile]}
                        hierarchyType={currentProfile}
                        demo={true}
                        metricsByType={metricsByType}
                        onProfileChange={(newProfile) => {
                            setCurrentProfile(newProfile);
                        }}
                    />
                </Box>

                <Box sx={{ pt: { xs: 2, sm: 3, md: 4 } }}>
                    <Grid container spacing={2}>
                        {[
                            {
                                title: "Interactive Controls",
                                description: "Customize colors, opacity, and data visualization. Highlight areas matching your criteria, and explore multiple metrics simultaneously with our intuitive mapping tools"
                            },
                            {
                                title: "Detailed Analytics",
                                description: "Get hyper-local insights with census tract level data - more detailed than zip code or county level analysis"
                            },
                            {
                                title: "Multi-Level View",
                                description: "Access comprehensive metrics across Economic, Demographic, Social, and Housing categories for complete analysis with <strong>500+</strong> metrics"
                            }
                        ].map((feature, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <Box sx={{ 
                                    p: 3,
                                    backgroundColor: colors.lightest,
                                    borderRadius: '12px',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
                                }}>
                                    <MKTypography variant="h6" mb={1}>
                                        {feature.title}
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text" dangerouslySetInnerHTML={{
                                        __html: feature.description
                                    }} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </MKBox>
    );

    return (
        <>
            {/* Hero Section */}
            {renderHeroSection()}

            {/* Demo Section */}
            {renderDemoSection()}

            {/* Subscription Section */}
            <MKBox id="pricing">
                <SubscriptionPage />
            </MKBox>

            {/* Metrics Display Section */}
            <MKBox 
                sx={{ 
                    background: 'white',
                    borderTop: '1px solid rgba(0,0,0,0.1)'
                }}
            >
                <LandingMetricDisplay colors={colors} />
            </MKBox>
        </>
    );
};

export default BuyIncomeMap; 