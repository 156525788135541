import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Tooltip from '@mui/material/Tooltip';
import { tooltipStyles } from './utils/tooltipStyles';

const LocationMetricDisplayBar = ({ 
  selectedState,
  selectedFeature,
  viewMode,
  selectedCounty,
  primaryMetric,
  handleBackToCounties,
  handleViewTracts,
  isPanelVisible
}) => {
  const RadioIcon = ({ isSelected }) => (
    <span style={{ 
      marginRight: '4px',
      display: 'inline-flex',
      alignItems: 'center'
    }}>
      {isSelected ? (
        <RadioButtonCheckedIcon sx={{ fontSize: 16, color: '#2196f3' }} />
      ) : (
        <RadioButtonUncheckedIcon sx={{ fontSize: 16, color: '#666' }} />
      )}
    </span>
  );

  return (
    <div style={{
      position: "absolute",
      top: "10px",
      left: isPanelVisible ? "410px" : "10px",
      right: "70px",
      backgroundColor: "white",
      padding: "8px 16px",
      zIndex: 999,
      border: "1px solid #ddd",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      gap: "24px",
      height: "48px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      transition: "left 0.3s ease-in-out"
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: '4px'
      }}>
        <button style={{
          padding: '4px 12px',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          border: 'none',
          cursor: 'pointer',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <span><strong>State:</strong> {' ' +selectedState?.toUpperCase() || 'State'}</span>
        </button>

        <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />

        <Tooltip 
          title={
            viewMode === 'tracts' 
              ? "Click to return to county view" 
              : "Currently in County View, select a county to explore data"
          }
          componentsProps={{
            tooltip: { sx: tooltipStyles.tooltip },
            arrow: { sx: tooltipStyles.arrow }
          }}
          arrow
        >
          <button style={{
            padding: '4px 12px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            opacity: selectedCounty || (viewMode === 'counties' && selectedFeature) ? 1 : 0.5,
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleBackToCounties()}
          >
            <RadioIcon isSelected={viewMode === 'counties'} />
            {viewMode === 'counties' && selectedFeature ? (
              <>
                <strong>County:</strong> 
                <span>{' ' +selectedFeature.properties.NAMELSAD}</span>
              </>
            ) : viewMode === 'tracts' ? (
              <>
                <strong>County:</strong> 
                <span>{' ' +(selectedFeature?.properties.NAMELSADCO || selectedCounty?.properties.NAMELSAD)}</span>
              </>
            ) : (
              'County'
            )}
          </button>
        </Tooltip>

        {(viewMode === 'tracts' || (viewMode === 'counties' && selectedFeature)) && (
          <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />
        )}

        {(viewMode === 'tracts' || (viewMode === 'counties' && selectedFeature)) && (
          <Tooltip 
            title={
              viewMode === 'counties' && selectedFeature
                ? "Click to view census tracts for this county"
                : viewMode === 'tracts'
                ? "Currently viewing tract data"
                : "Select a county first to view its tracts"
            }
            componentsProps={{
              tooltip: { sx: tooltipStyles.tooltip },
              arrow: { sx: tooltipStyles.arrow }
            }}
            arrow
          >
            <button style={{
              padding: '4px 12px',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              border: 'none',
              cursor: viewMode === 'counties' && selectedFeature ? 'pointer' : 'default',
              fontSize: '14px',
              opacity: viewMode === 'tracts' ? 1 : 0.5,
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              if (viewMode === 'counties' && selectedFeature) {
                handleViewTracts(selectedFeature.properties.GEOID);
              }
            }}
            >
              <RadioIcon isSelected={viewMode === 'tracts'} />
              {viewMode === 'tracts' && selectedFeature ? (
                <>
                  <strong>Tract:</strong> 
                  <span>{' ' +selectedFeature.properties.GEOIDFQ}</span>
                </>
              ) : (
                <>
                  <strong>Tract:</strong> 
                </>
              )}
            </button>
          </Tooltip>
        )}

        {primaryMetric && (
          <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />
        )}

        {primaryMetric && (
          <Tooltip 
            title={`Currently viewing map with data for ${primaryMetric.label}`}
            componentsProps={{
              tooltip: { sx: tooltipStyles.tooltip },
              arrow: { sx: tooltipStyles.arrow }
            }}
            arrow
          >
            <button style={{
              padding: '4px 12px',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              border: 'none',
              cursor: 'default',
              fontSize: '14px',
            }}>
              <strong>Metric:</strong> <span>{' ' +primaryMetric.label}</span>
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

LocationMetricDisplayBar.propTypes = {
  selectedState: PropTypes.string,
  selectedFeature: PropTypes.object,
  viewMode: PropTypes.oneOf(['counties', 'tracts']).isRequired,
  selectedCounty: PropTypes.object,
  primaryMetric: PropTypes.object,
  handleBackToCounties: PropTypes.func.isRequired,
  handleViewTracts: PropTypes.func.isRequired,
  isPanelVisible: PropTypes.bool.isRequired
};

export default LocationMetricDisplayBar; 