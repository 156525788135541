/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import typography from "assets/theme/base/typography";
import { Link as RouterLink } from 'react-router-dom';

function SimpleFooter({ light }) {
  const { size } = typography;

  const DownloadSample = () => {
    const fileUrl = 'https://terragrasp-images.s3.us-west-2.amazonaws.com/sample_products/TerraGrasp_Income_by_zip_Sample_Data.xlsx';
    window.location.href = fileUrl;
  };

  // Define all footer links
  const footerSections = [
    {
      title: "Related Resources",
      links: [
        { name: "Request Custom Data", href: "/data-request" },
        { name: "API Access", href: "/api-inquery" },
        { name: "Custom Analysis", href: "/unique-request" },
      ],
    },
    {
      title: "Help & Support",
      links: [
        { name: "About Our Data", href: "/about-us" },
        { name: "Contact Support", href: "/contact-us" },
        { name: "Site Map", href: "/sitemap" },
      ],
    },
    {
      title: "Get Started",
      links: [
        { name: "Sign Up for Full Access", href: "/user/sign-up" },
        { name: "Download Sample Data", href: "#", onClick: DownloadSample },
      ],
    },
  ];

  return (
    <Container>
      <MKBox
        width="100%"
        display="flex"
        flexDirection="column"
        py={1}
      >
        {/* Main Footer Navigation */}
        <MKBox
          component="ul"
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="flex-start"
          gap={{ xs: 2,  md: 8, lg: 20 }}
          sx={{ listStyle: "none", p: 0, m: 0 }}
        >
          {footerSections.map((section) => (
            <MKBox
              key={section.title}
              component="li"
              width={{ xs: "100%", md: "auto" }}
              mb={{ xs: 3, md: 0 }}
              textAlign="center"
            >
              <MKTypography 
                variant="button" 
                fontWeight="bold" 
                color={light ? "white" : "text"}
                textAlign="center"
              >
                {section.title}
              </MKTypography>
              <MKBox 
                component="ul" 
                p={0} 
                m={0} 
                sx={{ 
                  listStyle: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                {section.links.map((link) => (
                  <MKBox key={link.name} component="li" p={0} m={0} lineHeight={1.25}>
                    {link.onClick ? (
                      <MKTypography
                        onClick={link.onClick}
                        variant="button"
                        fontWeight="regular"
                        color={light ? "white" : "text"}
                        sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                      >
                        {link.name}
                      </MKTypography>
                    ) : (
                      <Link
                        component={RouterLink}
                        to={link.href}
                        sx={{ textDecoration: 'none' }}
                      >
                        <MKTypography
                          variant="button"
                          fontWeight="regular"
                          color={light ? "white" : "text"}
                        >
                          {link.name}
                        </MKTypography>
                      </Link>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </MKBox>
          ))}
        </MKBox>

        {/* Updated Copyright section */}
        <MKBox
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={6}
        >
          <MKTypography
            variant="button"
            fontWeight="regular"
            color={light ? "white" : "text"}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()} TerraGrasp
          </MKTypography>
        </MKBox>
      </MKBox>
    </Container>
  );
}

// Updated default props
SimpleFooter.defaultProps = {
  light: false,
};

// Updated prop types
SimpleFooter.propTypes = {
  light: PropTypes.bool,
};

export default SimpleFooter;
