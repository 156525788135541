import React, {useEffect} from 'react';
import { Outlet, useNavigate } from "react-router-dom"
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import ErrorPage from '../Error';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const AccountCheck = (props) => {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();  
    const userDetails = useAuthUser()

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/user/login");
        } else if (userDetails && userDetails.email_verified === false) {
            navigate("/user/verify-email/check-email");
        }
  }, [isAuthenticated, navigate, userDetails]);


  

  if(isAuthenticated ){
  return (
        <>
        {props.error ? (
            <ErrorPage />
        ) : (
            <> 
            <Outlet> </Outlet>
            </>
        )} 
        </>
        );
    }
    else {
        
        return(
        <>
            <ErrorPage />
        </>
        )
    }
}

export default AccountCheck;
