import React from 'react';
import "./App.css";
import {createBrowserRouter, RouterProvider, Navigate, useParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import RootLayout from "./components/RootLayout";
import Login from "./components/Users/Login";
import ForgotPassword from "./components/Users/ForgotPassword";
import SignUp from "./components/Users/SignUp";
import Contact from "./components/Forms/Contact";
import AdminCreateProduct from './components/Admin/AdminCreateProduct';
import AccountPage from "./components/Users/AccountPage";
import Logout from "./components/Users/Logout";
import EmailVerification from "./components/Users/EmailVerification";
import ResendEmailVerification from "./components/Users/ResendEmailVerification";
import PasswordReset from "./components/Users/ResetPassword";
import AdminPanel from "./components/Admin/AdminPanel";
import IncomeByZip from "./components/product/IncomeByZip";
import IncomeByZipData from "./components/product/IncomeByZipData";
import AdminViewProducts from "./components/Admin/AdminViewProducts";
import AdminCheck from "./components/Admin/AdminCheck";
import ErrorPage from "./components/Error";
import AccountCheck from "./components/Users/AccountCheck";
import ShoppingCart from "./components/Cart/ShoppingCart";
import AdminViewStripeProducts from "./components/Admin/AdminViewStripeProducts";
import AdminViewStripePrices from "./components/Admin/AdminViewStripePrices";
import AdminDetailProduct from "./components/Admin/AdminProductDetails";
import CheckoutLogin from "./components/Users/CheckoutLogin";
import CustomLanding from "./components/CustomLanding";
import AboutUs from "./components/basics/AboutUs";
import AdminCreateProductCSVUpload from "components/Admin/AdminCreateProductCSVUpload";
import AdminCreateSubscriptionProduct from "components/Admin/AdminCreateSubscriptionProduct";
import APIRequest from "components/Forms/API_request";
import DataRequest from "components/Forms/DataRequest";
import UniqueRequest from "components/Forms/UniqueRequest";
import CheckoutSuccessRedirect from "components/Users/CheckoutReturn";
import CheckEmailForVerification from "components/Users/CheckEmailForVerification";
import Sitemap from "components/SiteMap";
import SitemapLink from 'components/SiteMapLinks';
import { statesList } from 'components/utils/stateList'; // Import the list of states
import StateMap from 'components/maps/StateMap';
import SubscriptionPage from 'components/Subscriptions/SubscriptionPage';
import CheckoutSuccess from 'components/Subscriptions/CheckoutSuccess';
import SetUpPassword from 'components/Users/SetUpPassword';
import AdminCreateSubscriptionProductCSV from "components/Admin/AdminCreateSubscriptionProductCSV";
import BuyIncomeMap from 'components/product/BuyIncomeMap';

// Wrapper component to validate stateName
const ValidateStateRoute = ({ element: Element }) => {
  const { stateName } = useParams();
  if (!statesList.includes(stateName)) {
    return <ErrorPage />;
  }
  return <Element />;
};

// Add this wrapper component
const StateMapWrapper = () => {
  const { stateName } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem('_auth');

    if (!token) {
      navigate('/account');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const allowedStates = decodedToken.state_names || [];
      
      if (!allowedStates.includes(stateName.toLowerCase())) {
        navigate('/account');
      }
    } catch (error) {
      navigate('/account');
    }
  }, [stateName, navigate]);

  return <StateMap initialState={stateName} />;
};

const router = createBrowserRouter([
  {path: "/", 
    element: <RootLayout relative_status={false}  />,
    errorElement: <RootLayout error={true} /> ,
    children: [
      { path: '/*', element: <ErrorPage />},
      { index: true, element: <CustomLanding />},
      { path: 'about-us', element: <AboutUs/>},
      { path: 'state-income-data',
        children: [
          { 
            path: ':stateName', 
            element: <ValidateStateRoute element={IncomeByZipData} /> 
          }
        ]
      },
    ]
  },
  {path: "/sitemap.xml", 
    element: <Sitemap />,
  },
  {path: "/", 
    element: <RootLayout relative_status={true}  />,
    errorElement: <RootLayout error={true} /> ,

    children: [
      { path: 'sitemap', element: <SitemapLink/>},
      { path: 'contact-us', element: <Contact />},
      { path: 'api-inquery', element: <APIRequest />},
      { path: 'data-request', element: <DataRequest />},
      { path: 'unique-request', element: <UniqueRequest />},
      { path: 'subscriptions', element: <SubscriptionPage /> },
      { path: 'income-map', element: <BuyIncomeMap /> },
      
      { path: 'income-by-zipcode',
        children: [
          { index: true, element: <Navigate to="/income-by-zipcode/usa" replace /> },
          { path: ':stateName', element: <IncomeByZip /> }
        ]
      },
     
      {path: "/user", 
        errorElement: <RootLayout error={true} /> ,
        children: [
          { path: 'login', element: <Login />},
          { path: 'checkout-success', element: <CheckoutSuccessRedirect />},
          { path: 'checkout-success-create-account', element: <CheckoutSuccess />},
          { path: 'login-checkout', element: <CheckoutLogin />},
          { path: 'logout', element: <Logout /> },
          { path: 'reset', element: <ForgotPassword />},
          { path: 'reset-password/:password_reset_token', element: <PasswordReset/>},
          { path: 'set-up-password/:password_reset_token', element: <SetUpPassword/>},
          { path: 'sign-up', element: <SignUp/>},
          { path: 'sign-in-new-user', element: <SignUp/>},
          { path: 'sign-up-checkout', element: <SignUp checkoutSignUp={true}/>},
          { path: 'verify-email/resend-email-verification', element: <ResendEmailVerification/>},
          { path: 'verify-email/check-email', element: <CheckEmailForVerification/>},
          { path: 'verify-email/:email_verify_token', element: <EmailVerification/>},
          { path: 'cart', element: <ShoppingCart />},
          ]
      }]
    },
    {path: "/", 
      element: <RootLayout relative_status={true}  />,
      errorElement: <RootLayout error={true} /> ,
      children: [
        {path: "/account", 
        element: <AccountCheck />,
        errorElement: <RootLayout error={true} /> ,
        children: [
          { path: '', element: <AccountPage/>},
          ]
        },
        {path: "/admin", 
        element: <AdminCheck />,
        errorElement: <RootLayout error={true} /> ,
        children: [
          { path: 'create-product', element: <AdminCreateProduct />},
          { path: 'create-product-csv', element: <AdminCreateProductCSVUpload />},
          { path: 'create-subscription-csv', element: <AdminCreateSubscriptionProductCSV />},
          { path: 'stripe_products', element: <AdminViewStripeProducts />},
          { path: 'stripe_prices', element: <AdminViewStripePrices />},
          { path: 'products', element: <AdminViewProducts/>},
          { path: 'detailed-product/:id', element: <AdminDetailProduct/>},
          { path: 'create-subscription', element: <AdminCreateSubscriptionProduct/>},
          {path: 'panel', element: <AdminPanel/> },
          ]
        }
      ]
    },
    {path: "/account", 
      element: <AccountCheck />,
      errorElement: <RootLayout error={true} /> ,
      children: [
        { path: 'map-app/:stateName', element: <StateMapWrapper />},
        ]
      },
    {path: "/admin", 
        element: <AdminCheck />,
        errorElement: <RootLayout error={true} /> ,
        children: [
          { path: 'map-app/:stateName', element: <StateMapWrapper />},
          ]
    },
    {path: "/sitemap.xml", 
      element: <Sitemap />,
    },

]);

function RoutesComponent() {
  return (
    <RouterProvider router={router} />
  );
}

export default RoutesComponent;
