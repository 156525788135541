import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import StateSVGComponent from 'components/images/StateSVGs';
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StateDropDown = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.initialSet || '');
    const { showPrice = true } = props;

    useEffect(() => {
        if (props.initialSet) {
            setSelectedValue(props.initialSet);
        }
    }, [props.initialSet]);

    const handleDropdownChange = (event, value) => {
        const selectedProduct = value.product_name;
        setSelectedValue(selectedProduct);
        props.onSelectButton(selectedProduct);
    };

    const initialOption = props.products?.find(
        product => product.product_name.toLowerCase() === selectedValue?.toLowerCase()
    );

    return (
        <>
            <Autocomplete
                id="country-select-demo"
                sx={{ 
                    width: "100%",
                    backgroundColor: '#ffffff',
                    borderRadius: '8px',
                    fontFamily: 'Tahoma, Arial, sans-serif',
                    '& .MuiInputLabel-root': {
                        color: 'rgba(0, 0, 0, 0.7)',
                        fontFamily: 'Tahoma, Arial, sans-serif',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#606060',
                    },
                    '& .MuiOutlinedInput-root': {
                        color: '#606060',
                        backgroundColor: '#ffffff',
                        fontSize: '1.1rem',
                        fontWeight: 'normal',
                        textAlign: 'center',
                        fontFamily: 'Tahoma, Arial, sans-serif',
                        '& input': {
                            textAlign: 'center',
                            fontFamily: 'Tahoma, Arial, sans-serif',
                            padding: '6px 14px',
                        },
                        '& fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover fieldset': {
                            borderColor: '#606060',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#606060',
                        }
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#606060',
                        fontSize: '1.2rem',
                    },
                    ...props.sx
                }}
                options={props.products}
                autoHighlight
                disableClearable
                value={initialOption || null}
                getOptionLabel={(option) => option.product_name}
                onChange={(event, value) => handleDropdownChange(event, value)}
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                        <Box
                            key={key}
                            component="li"
                            sx={{ 
                                '& > img': { mr: 2, flexShrink: 0 },
                                color: '#000000',
                                py: 0.5,
                            }}
                            {...optionProps}
                        >
                            <Grid container>
                                <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                    <StateSVGComponent stateName={option.product_name} size={20} color={"#4681E4"} />
                                </Grid>
                                <Grid item xs={6} sx={{ 
                                    textAlign: 'left',
                                    fontSize: '0.9rem'
                                }}>
                                    {option.product_name.replace(/_/g, ' ')}
                                    {showPrice && option.price && ` $${option.price / 100}`}
                                </Grid>
                            </Grid>
                        </Box>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                    />
                )}
            />
        </>
    );
};

export default StateDropDown;
