const TractStyler = ({
  feature,
  primaryMetric,
  selectedTractId,
  metricsData,
  selectedMetricType,
  opacityColorGradient,
  lineColor,
  opacitySelected,
  opacityUnselected,
  selectedColorGradient,
  mapColors,
  getMetricTypeIndex,
  customRanges = null
}) => {
  const isSelected = selectedTractId === feature.properties.GEOID;

  if (!primaryMetric || !metricsData?.headers || !metricsData?.data) {
    // Default style when no primary metric is selected or no data available
    return {
      fillColor: '#FFA500',
      weight: isSelected ? 3 : 2,
      opacity: opacityColorGradient,
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  }

  const countyId = feature.properties.COUNTYFP;
  const tractId = feature.properties.TRACTCE;
  const metricValues = metricsData.data[countyId]?.[tractId] || [];
  const metricIndex = metricsData.headers.indexOf(primaryMetric.item_mapping[getMetricTypeIndex(selectedMetricType)]);
  const metricValue = Number(metricValues[metricIndex]);
  
  // Handle missing or invalid data
  if (metricValue === undefined || metricValue === null || Number(metricValue) < 0) {
    return {
      fillColor: '#808080', // Gray for no data
      weight: isSelected ? 3 : 2,
      opacity: opacityColorGradient,
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  }

  let colorIndex;
  let color;
  
  if (customRanges && customRanges.length === 6) {
    // Use custom ranges to determine color index
    if (metricValue <= customRanges[1]) colorIndex = 0;
    else if (metricValue <= customRanges[2]) colorIndex = 1;
    else if (metricValue <= customRanges[3]) colorIndex = 2;
    else if (metricValue <= customRanges[4]) colorIndex = 3;
    else colorIndex = 4;
    
    const colors = mapColors.list_of_5[selectedColorGradient];
    color = colors[colorIndex];
  } else {
    // Use original dynamic calculation
    const allValues = Object.values(metricsData.data[countyId] || {})
      .map(values => values[metricIndex])
      .filter(value => value !== null && value !== undefined && Number(value) >= 0)
      .map(Number);
    
    const min = Math.min(...allValues);
    const max = Math.max(...allValues);
    const range = max - min;
    
    const percentage = (metricValue - min) / (max - min);
    colorIndex = Math.min(Math.floor(percentage * 5), 4);
    
    const colors = mapColors.list_of_5[selectedColorGradient];
    color = colors[colorIndex];
  }

  return {
    fillColor: color,
    weight: isSelected ? 3 : 2,
    opacity: opacityColorGradient,
    color: isSelected ? lineColor[0] : lineColor[1],
    dashArray: '3',
    fillOpacity: isSelected ? opacitySelected : opacityUnselected
  };
};

export default TractStyler; 