import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import AdminSidePanel from './AdminSidePanel';

const AdminCreateSubscriptionProductCSV = () => {
  const [file, setFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState({ total: 0, current: 0, success: 0, failed: 0 });
  const authHeader = useAuthHeader();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const processCSV = (csv) => {
    const lines = csv.split('\n');
    const headers = lines[0].split(',').map(h => h.trim());
    const data = [];
    
    for (let i = 1; i < lines.length; i++) {
      if (!lines[i].trim()) continue;
      
      const values = lines[i].split(',').map(v => v.trim());
      const entry = {};
      headers.forEach((header, index) => {
        if (header === 'monthly_price' || header === 'yearly_price') {
          entry[header] = parseInt(values[index], 10);
        } else {
          entry[header] = values[index];
        }
      });
      data.push(entry);
    }
    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowAlert(false);
    setShowErrorAlert(false);
    setProcessing(true);

    if (!file) {
      setShowErrorAlert(true);
      setAlertMessage('Please select a CSV file');
      setProcessing(false);
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const products = processCSV(text);
        
        setProgress({ total: products.length, current: 0, success: 0, failed: 0 });

        for (let product of products) {
          try {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/subscriptions/create_subscription_products`,
              {
                ...product,
                state_name: product.state_name.toLowerCase().replace(/\s+/g, '_')
              },
              {
                headers: {
                  'Authorization': authHeader,
                  'Content-Type': 'application/json'
                }
              }
            );
            setProgress(prev => ({ 
              ...prev, 
              current: prev.current + 1,
              success: prev.success + 1 
            }));
          } catch (error) {
            console.error(`Error creating product for ${product.state_name}:`, error);
            setProgress(prev => ({ 
              ...prev, 
              current: prev.current + 1,
              failed: prev.failed + 1 
            }));
          }
        }

        setShowAlert(true);
        setFile(null);
        e.target.form.reset();
      };
      reader.readAsText(file);
    } catch (error) {
      setShowErrorAlert(true);
      setAlertMessage(error.response?.data?.detail || 'An error occurred');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <h1>Terra Grasp Admin Panel</h1>
          <h2>Bulk Create Subscription Products</h2>
          
          {showAlert && (
            <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
              Processing complete! Successfully created {progress.success} products, Failed: {progress.failed}
            </Alert>
          )}
          {showErrorAlert && (
            <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
              {alertMessage}
            </Alert>
          )}

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="csvFile">
              <Form.Label>Upload CSV File</Form.Label>
              <Form.Control
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                required
                disabled={processing}
              />
              <Form.Text className="text-muted">
                CSV should include columns: product_name, description, monthly_price, yearly_price, app_product_type, state_name
              </Form.Text>
            </Form.Group>

            {processing && (
              <div className="mt-3">
                <p>Processing: {progress.current} of {progress.total}</p>
                <p>Success: {progress.success} | Failed: {progress.failed}</p>
              </div>
            )}

            <Row className="mt-3">
              <Col md={6}>
                <Button 
                  variant="primary" 
                  type="submit"
                  disabled={processing}
                >
                  {processing ? 'Processing...' : 'Upload and Create'}
                </Button>
              </Col>
              <Col md={6}>
                <Link to="/admin/panel">
                  <Button variant="link">
                    Admin Panel
                  </Button>
                </Link>
              </Col>
            </Row>
          </Form>

          <div className="mt-4">
            <h3>CSV Format Example:</h3>
            <pre className="bg-light p-3 border rounded">
              product_name,description,monthly_price,yearly_price,app_product_type,state_name
              California Map,A map product,1299,10999,data_map_subscription,california
              Texas Map,A map product,1299,10999,data_map_subscription,texas
            </pre>
          </div>
        </Col>
        <Col md={4}>
          <AdminSidePanel />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCreateSubscriptionProductCSV; 